<svg viewBox="0 0 129 21" version="1.1" width="129" height="21" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
  <path d="M 2.5522575,0.46025505 H 7.3866672 L 4.8344102,16.578345 H 3.4040003e-7 Z" style="stroke-width: 0" />
  <path
    d="m 16.609128,-2.6762876e-8 h 4.374317 L 18.360229,16.578345 H 9.5314792 A 2.3760026,2.37684 0 0 1 7.148609,13.745829 L 8.164934,7.3343148 C 8.412148,5.7909715 9.9023002,4.5017994 11.44281,4.5017994 h 4.447566 z M 11.573284,13.542035 h 2.884166 l 0.949943,-6.0130851 h -2.900188 z"
    style="stroke-width: 0"
  />
  <path
    d="M 21.610637,7.3228655 C 21.855559,5.7795227 23.348003,4.49035 24.890802,4.49035 h 5.294503 a 2.3782916,2.3791299 0 0 1 2.385159,2.8325155 L 32.320958,8.8822376 C 32.078321,10.423291 30.583593,11.712463 29.040793,11.712463 H 25.289091 L 25.0785,13.07949 h 6.816703 l -1.9388,3.498855 h -6.981511 a 2.3737136,2.3745502 0 0 1 -2.38058,-2.832516 z m 4.305646,0.4373539 -0.228903,1.5181552 h 2.437807 l 0.240347,-1.5181552 z"
    style="stroke-width: 0"
  />
  <path
    d="m 33.179343,12.179588 c 0.247214,-1.543343 1.741944,-2.8393853 3.280165,-2.8393853 h 3.756282 l 0.212876,-1.3578671 h -6.585508 l 1.936511,-3.4988549 h 6.745741 a 2.3782916,2.3791299 0 0 1 2.385159,2.8325154 L 43.445597,16.571475 H 35.314998 A 2.3760026,2.37684 0 0 1 32.932129,13.73896 Z m 6.409255,1.128884 0.228903,-1.518155 H 37.38885 l -0.242637,1.518155 z"
    style="stroke-width: 0"
  />
  <path
    d="m 48.115197,16.578345 a 2.3760026,2.37684 0 0 1 -2.38058,-2.832516 L 47.909185,0.00686996 h 4.376606 L 50.278321,12.642133 h 2.746824 l -1.998314,3.943081 z"
    style="stroke-width: 0"
  />
  <path
    d="m 52.668058,16.578345 2.00747,-3.9614 h 6.997536 l 0.382262,-2.392869 H 57.17056 c -1.632072,0 -2.623217,-1.1746809 -2.362269,-2.8096174 L 55.46524,3.2698723 a 3.2824544,3.2836113 0 0 1 3.261853,-2.80961725 h 9.950368 L 66.667702,4.4216556 h -6.548885 l -0.379976,2.3997379 h 4.880188 c 1.634359,0 2.623221,1.1746814 2.366848,2.8096173 l -0.656946,4.1468762 a 3.2824544,3.2836113 0 0 1 -3.252703,2.800458 z"
    style="stroke-width: 0"
  />
  <path
    d="m 69.638853,4.49035 h 4.369738 l -1.355104,8.58914 h 2.897899 l 1.373415,-8.58914 h 4.374315 l -1.913619,12.087995 h -8.83104 A 2.3737136,2.3745502 0 0 1 68.17388,13.745829 Z"
    style="stroke-width: 0"
  />
  <path
    d="m 84.984438,21 h -4.376606 l 2.61177,-16.50965 h 8.821888 a 2.3782916,2.3791299 0 0 1 2.394312,2.8325155 l -1.016326,6.4229635 c -0.244941,1.545633 -1.737366,2.832516 -3.280167,2.832516 H 85.68259 Z m 4.081326,-7.457965 0.952233,-6.0130851 h -2.907058 l -0.949942,6.0130851 z"
    style="stroke-width: 0"
  />
  <path
    d="M 98.427855,21 H 94.053534 L 96.667596,4.49035 h 8.817304 a 2.3782916,2.3791299 0 0 1 2.38516,2.8325155 l -1.01861,6.4229635 c -0.24264,1.545633 -1.73508,2.832516 -3.27788,2.832516 h -4.445278 z m 4.081315,-7.457965 0.95224,-6.0130851 h -2.90248 l -0.947654,6.0130851 z"
    style="stroke-width: 0"
  />
  <path
    d="m 110.9877,16.578345 a 2.3760026,2.37684 0 0 1 -2.38516,-2.832516 l 2.17915,-13.73895904 h 4.37432 L 113.09589,13.086359 h 1.98001 l -1.93881,3.498855 z"
    style="stroke-width: 0"
  />
  <path
    d="M 126.83001,18.169774 C 126.58283,19.713117 125.09036,21 123.54526,21 h -9.13776 l 1.80375,-3.269872 h 6.31083 l 0.22888,-1.373896 h -4.45215 a 2.3805806,2.3814197 0 0 1 -2.38516,-2.834806 l 1.42838,-9.026496 h 4.37431 l -1.32306,8.357867 h 2.90249 L 124.61882,4.49493 H 129 Z"
    style="stroke-width: 0"
  />
</svg>
