<form [formGroup]="form">
  <div mat-dialog-title>Save cart</div>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Save Cart</mat-label>
      <input
        type="text"
        placeholder="Name of cart"
        matInput
        #saveCartAs
        autocomplete="off"
        formControlName="saveCartAs"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let cart of filteredCarts$ | async" [value]="cart">
          {{ cart.name }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="form.get('saveCartAs')?.hasError('duplicate')"> A saved cart with that name already exists. </mat-error>
      <mat-error *ngIf="form.get('saveCartAs')?.hasError('minlength')"> Name is to short. {{ saveCartAs.value.length }} / 3 </mat-error>
      <mat-error *ngIf="form.get('saveCartAs')?.hasError('maxlength')"> Name is to long. {{ saveCartAs.value.length }} / 50 </mat-error>
      <mat-error *ngIf="form.get('saveCartAs')?.hasError('required')"> Name is required. </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <div class="save-buttons">
      <ng-container *ngIf="selectedCartId$ | async as selectedCartId">
        <button
          mat-stroked-button
          [mat-dialog-close]="{ action: 'replace', value: selectedCartId }"
          color="accent"
          [disabled]="!isValidUpdate"
        >
          Replace Cart
        </button>
        <button
          mat-stroked-button
          [mat-dialog-close]="{ action: 'merge', value: selectedCartId }"
          color="accent"
          [disabled]="!isValidUpdate"
        >
          Add Items
        </button>
      </ng-container>

      <button mat-stroked-button [mat-dialog-close]="{ action: 'new', value: saveCartAs.value }" color="accent" [disabled]="form.invalid">
        Create
      </button>
    </div>
  </mat-dialog-actions>
</form>
