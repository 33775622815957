<div class="instock">{{ stock | number }} in stock</div>
<div class="branch">{{ currentBranch?.name }}</div>
@if (totalStock === 0) {
  Available for Backorder
} @else {
  <a (click)="showPopup(); $event.stopPropagation()">Check other stores</a>
}

<ng-template #stockDialogTpl>
  <mat-dialog-content class="mat-typography" [class.no-cart-item]="!cartItem">
    <mat-radio-group
      name="branchSelectAQDF"
      aria-label="Select an option"
      class="stock-list"
      [value]="currentBranch?.branch"
      (change)="onBranchChange($event)"
      [disabled]="(busy | async) ?? false"
    >
      <mat-radio-button name="branchSelectAQDF" *ngFor="let branch of item?.stock" [value]="branch.branch">
        <div class="branch">
          <div class="name">{{ branch.name }}</div>
          <div class="stock">{{ branch.stock < 1 ? 0 : (branch.stock | number) }}</div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</ng-template>
