import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductAttribute, ProductAttributeValue } from '@idealsupply/ngclient-webservice-inventory';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
  selector: 'product-attribute-list',
  templateUrl: './product-attribute-list.component.html',
  styleUrls: ['./product-attribute-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class ProductAttributeListComponent {
  @Input()
  public attributes?: ProductAttribute[];

  constructor() {}

  public attrDetailType(detail: ProductAttributeValue): string {
    if (detail.spec?.startsWith('http://') || detail.spec?.startsWith('https://') || detail.spec?.startsWith('//')) {
      return 'link';
    }

    return 'text';
  }
}
