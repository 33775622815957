import { isPlatformBrowser } from '@angular/common';
import { computed, effect, inject, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { patchState, signalStoreFeature, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AppDimension } from 'common-ui';
import { BehaviorSubject, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { withAppBreakpoints } from './withAppBreakpoints';

export interface HideHeaderOnScrollSlice {
  hideHeaderOnScrollIsHidden: boolean;
}

const topSize = 100;
const throttleTimeMs = 100;
const minScrollDelta = 100;

export function withHideHeaderOnScroll() {
  const scrollPosition = new BehaviorSubject<number>(0);

  return signalStoreFeature(
    withState<HideHeaderOnScrollSlice>(() => ({
      hideHeaderOnScrollIsHidden: false,
    })),
    withAppBreakpoints(),
    withComputed(({ appBreakpointWidth }) => {
      const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
      const hideOnScrollEnabled = computed(() => {
        if (!isBrowser) return false;
        const appWidthBp = appBreakpointWidth();
        if (appWidthBp <= AppDimension.md) {
          return true;
        }
        return false;
      });

      const isAtTop = toSignal(scrollPosition.pipe(map((pos) => pos < topSize)), { initialValue: true });

      return {
        hideHeaderOnScrollEnabled: hideOnScrollEnabled,
        hideHeaderOnScrollIsAtTop: isAtTop,
      };
    }),
    withMethods((state) => {
      const updateScrollPosition = (pos: number) => {
        if (!state.hideHeaderOnScrollEnabled()) {
          pos = 0;
        }
        if (scrollPosition.value !== pos) {
          scrollPosition.next(pos);
        }
      };

      const showHeader = () => {
        patchState(state, { hideHeaderOnScrollIsHidden: false });
      };
      const hideHeader = () => {
        patchState(state, { hideHeaderOnScrollIsHidden: true });
      };
      const toggleHeader = () => {
        patchState(state, { hideHeaderOnScrollIsHidden: !state.hideHeaderOnScrollIsHidden() });
      };

      return {
        updateScrollPosition,
        showHeader,
        hideHeader,
        toggleHeader,
      };
    }),
    withHooks((state) => {
      let lastScrollPos = -1;
      const updateScrollHide = (scrollPos: number) => {
        if (lastScrollPos === -2) {
          lastScrollPos = scrollPos;
        }
        if (scrollPos < topSize) {
          patchState(state, { hideHeaderOnScrollIsHidden: false });
        } else {
          const delta = lastScrollPos - scrollPos;
          if (Math.abs(delta) >= minScrollDelta) {
            lastScrollPos = scrollPos;
            if (delta > 0) {
              patchState(state, { hideHeaderOnScrollIsHidden: false });
            } else {
              patchState(state, { hideHeaderOnScrollIsHidden: true });
            }
          }
        }
      };

      return {
        onInit: () => {
          scrollPosition
            .pipe(throttleTime(throttleTimeMs, undefined, { leading: true, trailing: true }), takeUntilDestroyed())
            .subscribe(updateScrollHide);

          effect(
            () => {
              state.hideHeaderOnScrollEnabled();
              patchState(state, { hideHeaderOnScrollIsHidden: false });
            },
            { allowSignalWrites: true },
          );
          effect(() => {
            state.hideHeaderOnScrollIsHidden();
            lastScrollPos = -2;
          });
        },
      };
    }),
  );
}
