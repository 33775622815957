import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { InventoryApi, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { BehaviorSubject, Observable, Subscription, firstValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface IPagedResult<T> {
  skip: number;
  limit: number;
  total: number;
  data: T[];
}

export abstract class PagedDataSource<T> extends DataSource<T> {
  protected subs: Subscription = new Subscription();
  private _debounceSub?: Subscription;
  private _currentResult: IPagedResult<T> = {
    skip: 0,
    limit: 0,
    total: 0,
    data: [],
  };
  private _data: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _debounce: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  protected changed: boolean = true;

  public get loading(): Observable<boolean> {
    return this._loading.asObservable();
  }
  public get dataChange(): Observable<T[]> {
    return this._data.asObservable();
  }
  public get data(): T[] {
    return this._data.value;
  }

  public constructor(skip: number = 0, limit: number = 0) {
    super();
    this._currentResult.skip = skip;
    this._currentResult.limit = limit;
  }

  public async initialize(): Promise<this> {
    await this.execUpdate();
    return this;
  }

  onDestroy(): void {
    this.subs.unsubscribe();
  }

  public connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    this._debounceSub = this._debounce.pipe(debounceTime(1)).subscribe(() => !this.changed || this.execUpdate());

    this.subs.add(
      collectionViewer.viewChange.subscribe((c) => {
        /* const start = c.start || 0;
        const end = c.end === Number.MAX_VALUE ? 0 : c.end;
        this.skip = start;
        this.limit = end - start;
        */
      }),
    );

    return this._data;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this._debounceSub?.unsubscribe();
    this._debounceSub = undefined;
  }

  public get skip(): number {
    return this._currentResult.skip ?? 0;
  }

  public set skip(value: number) {
    value = value < 1 ? 0 : value;
    if (this.skip !== value) {
      this.changed = true;
      this._currentResult.skip = value;
      this.invalidateResults();
    }
  }

  public get limit(): number {
    return this._currentResult.limit;
  }

  public set limit(value: number) {
    value = value < 1 ? 10 : value;
    if (this.limit !== value) {
      this.changed = true;
      this._currentResult.limit = value;
      this.invalidateResults();
    }
  }

  public get total(): number {
    return this._currentResult.total;
  }

  public get count(): number {
    return this._currentResult.data?.length || 0;
  }

  private async execUpdate(): Promise<void> {
    this.changed = false;
    this._loading.next(true);
    this._currentResult = await this.update();
    this._loading.next(false);
    this._data.next(this._currentResult.data);
  }

  public async reload() {
    await this.execUpdate();
  }

  protected abstract update(): Promise<IPagedResult<T>>;

  protected invalidateResults(): void {
    this._debounce.next();
  }
}

export abstract class InventoryListDataSource extends PagedDataSource<ProductListItem> {
  private _filter: IProductListFilter = {};

  constructor(
    protected readonly inventoryApi: InventoryApi,
    skip: number = 0,
    limit: number = -1,
  ) {
    super(skip, limit);
  }
}
export class PromoProductListDataSource extends InventoryListDataSource {
  constructor(
    private readonly promoId: string,
    inventoryApi: InventoryApi,
    skip: number = 0,
    limit: number = -1,
  ) {
    super(inventoryApi, skip, limit);
  }

  protected update(): Promise<IPagedResult<ProductListItem>> {
    return firstValueFrom(this.inventoryApi.getPromotionProducts(this.promoId, this.skip, this.limit)) as any;
  }
}

export interface IProductListFilter {
  lineCode?: string;
  partNumber?: string;
  partDescription?: string;
  vendorNum?: number;
  category?: string;
  subCategory?: string;
  subCategory2?: string;
  productAvailable?: boolean;
  partList?: string;
}
export class ProductListDataSource extends PagedDataSource<ProductListItem> {
  private _filter: IProductListFilter = {};

  constructor(
    private readonly inventoryApi: InventoryApi,
    skip: number = 0,
    limit: number = 10,
  ) {
    super(skip, limit);
  }

  private cleanFilter(data: IProductListFilter): IProductListFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const val = (data as any)[key];
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  private _compareFilter(a?: IProductListFilter, b?: IProductListFilter): boolean {
    return JSON.stringify(this.cleanFilter(a ?? {})) === JSON.stringify(this.cleanFilter(b ?? {}));
  }

  private _isFilterEmpty(a?: IProductListFilter): boolean {
    return this._compareFilter(a, undefined);
  }

  protected update(): Promise<IPagedResult<ProductListItem>> {
    const f = this._filter ?? {};
    f.vendorNum = Number.isNaN(+f.vendorNum!) ? undefined : f.vendorNum;
    this.cleanFilter(f);

    if (this._isFilterEmpty(f)) {
      return Promise.resolve({
        data: [],
        total: 0,
        skip: this.skip,
        limit: this.limit,
      });
    }

    return firstValueFrom(
      this.inventoryApi.searchProducts(
        f.lineCode,
        f.partNumber,
        f.partDescription,
        f.vendorNum || undefined,
        f.category || undefined,
        f.subCategory || undefined,
        f.subCategory2 || undefined,
        f.productAvailable,
        f.partList || undefined,
        !!f.partList ? 1 : undefined,
        undefined,
        this.skip,
        this.limit,
      ),
    ) as Promise<IPagedResult<ProductListItem>>;
  }

  public get filter(): IProductListFilter {
    return this._filter;
  }

  public set filter(value: IProductListFilter) {
    value ??= {};
    if (JSON.stringify(this.filter) !== JSON.stringify(value)) {
      this.changed = true;
      this._filter = value;
      this.skip = 0;
      this.invalidateResults();
    }
  }

  public override initialize(value?: IProductListFilter): Promise<this> {
    this._filter = value ?? {};
    return super.initialize();
  }
}
