import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ideal-text-logo',
  standalone: true,
  imports: [],
  templateUrl: './text-logo.component.html',
  styleUrls: ['../logo-base/logo-base.scss', './text-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextLogoComponent {}
