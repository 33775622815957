import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SavedListApi } from '@idealsupply/ngclient-webservice-shopping-cart';

export interface ViewPartsListDialogData {
  listId: string;
}

@Component({
  selector: 'view-parts-list-dialog',
  templateUrl: './view-parts-list-dialog.component.html',
  styleUrls: ['./view-parts-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ViewPartsListDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _dialogData: ViewPartsListDialogData,
    private readonly _api: SavedListApi,
  ) {}

  private get listId() {
    return this._dialogData.listId;
  }
}
