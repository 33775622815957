import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PromotionListItem } from '@idealsupply/ngclient-webservice-inventory';
import { MatCard, MatCardHeader, MatCardTitle, MatCardImage, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'product-promo-card-list-item',
  templateUrl: './promo-card-list-item.component.html',
  styleUrls: ['./promo-card-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardImage, MatCardContent],
})
export class PromoCardListItemComponent implements OnInit {
  @Input()
  public promo: PromotionListItem | undefined;

  constructor() {}

  ngOnInit(): void {}
}
