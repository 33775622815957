import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { SavedCartSearchItem, ShoppingCartApi } from '@idealsupply/ngclient-webservice-shopping-cart';
import { Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

export interface SaveCartDialogData {
  customerNumber: string;
}

@Component({
  selector: 'save-cart-dialog',
  templateUrl: './save-cart-dialog.component.html',
  styleUrls: ['./save-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    AsyncPipe,
  ],
})
export class SaveCartDialogComponent {
  private _savedCarts$ = of(this._dialogData.customerNumber).pipe(
    switchMap((cNum) => this._api.getSavedCarts(cNum)),
    map((r) => r.data),
    shareReplay(1),
  );

  public readonly form = new FormGroup({
    saveCartAs: new FormControl(undefined, {
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      asyncValidators: [
        (control) => {
          return this._savedCarts$.pipe(
            map((list) => {
              const value = typeof control.value === 'string' ? control.value : (control.value.name as string);
              return list.find((i) => i.name.toLowerCase() === value?.toLowerCase()) ? { duplicate: true } : null;
            }),
          );
        },
      ],
    }),
  });

  public filteredCarts$ = combineLatest([this._savedCarts$, this.form.valueChanges]).pipe(
    map(([carts, formValue]) => {
      if (typeof formValue.saveCartAs === 'object') {
        return [formValue.saveCartAs];
      }
      return carts.filter(
        (c) =>
          c.name.toLocaleLowerCase().startsWith((formValue.saveCartAs as any).toLocaleLowerCase()) ||
          c.name.toLocaleLowerCase().includes(` ${(formValue.saveCartAs as any).toLocaleLowerCase()}`),
      );
    }),
  ) as Observable<SavedCartSearchItem[]>;

  private _selectedCart$ = combineLatest([
    this._savedCarts$,
    this.form.valueChanges.pipe(
      map((v) => (typeof v.saveCartAs === 'string' ? (v.saveCartAs as string) : (v?.saveCartAs as any as SavedCartSearchItem)?.name)),
    ),
  ]).pipe(map(([list, formValue]) => list.find((i) => i.name.toLowerCase() === formValue?.toLowerCase())));

  public selectedCartId$ = this._selectedCart$.pipe(map((cart) => cart?.id));

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _dialogData: SaveCartDialogData,
    private readonly _api: ShoppingCartApi,
  ) {}

  public get isNewList() {
    return this.form.get('saveCartAs')?.hasError('duplicate') !== true;
  }

  public get isValidUpdate() {
    return !this.isNewList && Object.keys(this.form.get('saveCartAs')?.errors ?? {}).length === 1;
  }

  public displayFn(partList?: SavedCartSearchItem) {
    return partList?.name ?? '';
  }
}
