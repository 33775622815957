import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { CustomerService } from 'lib-customer';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'product-list-item-detail',
  templateUrl: './product-list-item-detail.component.html',
  styleUrls: ['./product-list-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLinkActive, RouterLink, NgIf, AsyncPipe],
})
export class ProductListItemDetailComponent {
  private _item$$ = new BehaviorSubject<ProductListItem | undefined>(undefined);

  public crossReference$ = combineLatest([this.customerService.customerNumberChange, this._item$$]).pipe(
    map(([customerNumber, item]) => {
      if (!customerNumber || !item) {
        return undefined;
      }

      const ref = item.crossReferences?.find((r) => r.customerNumber === customerNumber);
      return ref?.crossReference;
    }),
    distinctUntilChanged(),
  );

  @Input()
  public get item(): ProductListItem | undefined {
    return this._item$$.value;
  }

  public set item(value: ProductListItem | undefined) {
    this._item$$.next(value);
  }

  @Output()
  public titleClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  public constructor(private readonly customerService: CustomerService) {}

  public handleTitleClick(event: MouseEvent) {
    event.stopPropagation();
    const cEvent: CustomEvent<{
      mouseEvent: MouseEvent;
      item: ProductListItem;
    }> = new CustomEvent('itemClick', {
      detail: { mouseEvent: event, item: this.item as ProductListItem },
    });
    this.titleClick.emit(cEvent);
  }
}
