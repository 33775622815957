import { Type, inject } from '@angular/core';
import { signalStoreFeature, withState } from '@ngrx/signals';
import { FEATURE_IMPORT_COMPONENT } from 'common-util';

export interface FeatureImportComponentsSlice {
  featureImportComponents: Type<any>[];
}

export function withFeatureImportComponents() {
  return signalStoreFeature(
    withState<FeatureImportComponentsSlice>(() => ({
      featureImportComponents: inject(FEATURE_IMPORT_COMPONENT, { optional: true }) ?? [],
    })),
  );
}
