import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  booleanAttribute,
  computed,
  effect,
  inject,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ElementResizeDirective } from 'common-util';
import { AppQuickSearchResultsRenderTargetComponent } from '../../../render-targets/app-quick-search-results/app-quick-search-results-render-target.component';
import { AppSearchComponentStore } from '../state/app-search.component.store';
import { SearchServiceState } from '../state/SearchServiceState';
import { AppSearchWindowState } from './AppSearchWindowState';

@Component({
  selector: 'ideal-app-search-window',
  standalone: true,
  imports: [
    AppQuickSearchResultsRenderTargetComponent,
    MatCard,
    MatCardContent,
    MatProgressBar,
    MatProgressSpinner,
    CdkScrollable,
    ElementResizeDirective,
    JsonPipe,
    CdkTrapFocus,
  ],
  templateUrl: './app-search-window.component.html',
  styleUrl: './app-search-window.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ElementResizeDirective],
})
export class AppSearchWindowComponent {
  private readonly _appSearchStore = inject(AppSearchComponentStore);
  private readonly _elmRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly _resizeRef = inject(ElementResizeDirective, { self: true });
  private readonly _windowHeight = toSignal(this._resizeRef.heightChange, { initialValue: 1 });
  private readonly _windowWidth = toSignal(this._resizeRef.widthChange, { initialValue: 1 });

  protected readonly State = AppSearchWindowState;
  protected readonly query = this._appSearchStore.searchQuery;
  protected readonly error = this._appSearchStore.searchError;
  protected readonly results = this._appSearchStore.searchResults;
  protected readonly resultCount = this._appSearchStore.searchResultCount;

  @Output()
  public readonly focusStart = new EventEmitter<FocusEvent>();
  @Output()
  public readonly focusEnd = new EventEmitter<FocusEvent>();

  public readonly itemHeight = input(96);
  public readonly searchBarPosition = input.required<{ left: number; width: number }>();
  public readonly searchBarExpanded = input.required<boolean>();

  public readonly adjustItemHeightToFit = input(true, { transform: booleanAttribute });

  public readonly actualItemHeight = computed(() => {
    if (!this.adjustItemHeightToFit()) {
      return this.itemHeight();
    }
    return this._windowHeight() / Math.round(this._windowHeight() / this.itemHeight());
  });

  protected readonly state = computed(() => {
    const searchState = this._appSearchStore.searchState();
    const resultCount = this.resultCount();
    const query = this.query();

    if (!query) {
      return AppSearchWindowState.Idle;
    }

    if (!!resultCount) {
      return searchState === SearchServiceState.Loading ? AppSearchWindowState.Refreshing : AppSearchWindowState.Results;
    }

    switch (searchState) {
      case SearchServiceState.Typing:
        return AppSearchWindowState.Idle;
      case SearchServiceState.Loading:
        return AppSearchWindowState.Loading;
      case SearchServiceState.Error:
        return AppSearchWindowState.Error;
      default:
        return AppSearchWindowState.Empty;
    }
  });

  constructor() {
    effect(() => {
      this._elmRef.nativeElement.style.setProperty('--search-result-item--height', `${this.actualItemHeight()}px`);
    });
    effect(() => {
      const { left, width } = this.searchBarPosition();
      const windowWidth = this._windowWidth();
      if (this.searchBarExpanded() || windowWidth < 900) {
        this._elmRef.nativeElement.style.setProperty('--ideal-app-search-window--left', `0`);
        this._elmRef.nativeElement.style.setProperty('--ideal-app-search-window--width', `100%`);
      } else {
        this._elmRef.nativeElement.style.setProperty('--ideal-app-search-window--left', `${left}px`);
        this._elmRef.nativeElement.style.setProperty('--ideal-app-search-window--width', `${width}px`);
      }
    });
  }

  protected onFocusLast(event: FocusEvent) {
    this.focusEnd.emit(event);
  }
  protected onFocusFirst(event: FocusEvent) {
    this.focusStart.emit(event);
  }
}
