import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { SavedListApi, SavedListSearchItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

export interface SaveCartAsListDialogData {
  customerNumber: string;
}

@Component({
  selector: 'cart-save-cart-as-list-dialog',
  templateUrl: './save-cart-as-list-dialog.component.html',
  styleUrls: ['./save-cart-as-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    AsyncPipe,
  ],
})
export class SaveCartAsListDialogComponent {
  private _customerPartLists$ = of(this._dialogData.customerNumber).pipe(
    switchMap((cNum) => this._api.getSavedLists(cNum, 1)),
    map((r) => r.data),
    shareReplay(1),
  );

  public readonly form = new FormGroup({
    savePartsListAs: new FormControl(undefined, {
      validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      asyncValidators: [
        (control) => {
          return this._customerPartLists$.pipe(
            map((list) => {
              const value = typeof control.value === 'string' ? control.value : (control.value.name as string);
              return list.find((i) => i.name.toLowerCase() === value?.toLowerCase()) ? { duplicate: true } : null;
            }),
          );
        },
      ],
    }),
  });

  public filteredCustomerPartLists$ = combineLatest([this._customerPartLists$, this.form.valueChanges]).pipe(
    map(([lists, formValue]) => {
      if (typeof formValue.savePartsListAs === 'object') {
        return [formValue.savePartsListAs];
      }
      return lists.filter(
        (l) =>
          l.name.toLocaleLowerCase().startsWith((formValue.savePartsListAs as any).toLocaleLowerCase()) ||
          l.name.toLocaleLowerCase().includes(` ${(formValue.savePartsListAs as any).toLocaleLowerCase()}`),
      );
    }),
  ) as Observable<SavedListSearchItem[]>;

  private _selectedList$ = combineLatest([
    this._customerPartLists$,
    this.form.valueChanges.pipe(
      map((v) =>
        typeof v.savePartsListAs === 'string' ? (v.savePartsListAs as string) : (v?.savePartsListAs as any as SavedListSearchItem)?.name,
      ),
    ),
  ]).pipe(map(([list, formValue]) => list.find((i) => i.name.toLowerCase() === formValue?.toLowerCase())));

  public selectedListId$ = this._selectedList$.pipe(map((list) => list?.id));

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _dialogData: SaveCartAsListDialogData,
    private readonly _api: SavedListApi,
  ) {}

  public get isNewList() {
    return this.form.get('savePartsListAs')?.hasError('duplicate') !== true;
  }

  public get isValidUpdate() {
    return !this.isNewList && Object.keys(this.form.get('savePartsListAs')?.errors ?? {}).length === 1;
  }

  public displayFn(partList?: SavedListSearchItem) {
    return partList?.name ?? '';
  }
}
