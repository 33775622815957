@let vm = (vm$ | async)!;

<button mat-stroked-button class="call-for-price" [disabled]="true">Call for Price and Availability</button>
<button
  mat-stroked-button
  class="add-to-cart inverted"
  [disabled]="state() !== 'add'"
  (click)="add(vm.product); $event.stopImmediatePropagation()"
>
  @switch (state()) {
    @case ("adding") {
      Adding to Cart...
    }
    @case ("added") {
      Added to Cart
    }
    @default {
      Add to Cart
    }
  }
</button>

<cart-quantity-field [item]="vm.product" (click)="$event.stopImmediatePropagation()" />
