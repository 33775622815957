import { ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { IPagedResult, IProductListFilter, PagedDataSource } from './product-list-data-source';

export class ProductListItemArrayDataSource extends PagedDataSource<ProductListItem> {
  constructor(private readonly initialData: ProductListItem[]) {
    super(0, -1);
  }

  protected update(): Promise<IPagedResult<ProductListItem>> {
    return Promise.resolve({
      data: this.initialData,
      limit: -1,
      skip: 0,
      total: this.initialData.length,
    });
  }

  public get filter(): IProductListFilter {
    return {};
  }

  public set filter(value: IProductListFilter) {}

  public override initialize(value?: IProductListFilter): Promise<this> {
    return super.initialize();
  }
}
