import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { DownloadableResourceItem, Product } from '@idealsupply/ngclient-webservice-inventory';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'product-image-viewer',
  templateUrl: './product-image-viewer.component.html',
  styleUrls: ['./product-image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor],
})
export class ProductImageViewerComponent implements OnInit {
  @Input()
  public product?: Product;

  @HostBinding('class.noimage')
  public get noimage(): boolean {
    return !this.product?.images?.length;
  }

  public selectedIndex: number = 0;
  public get selectedImage(): DownloadableResourceItem | undefined {
    return this.product?.images?.length ? this.product?.images[this.selectedIndex] : undefined;
  }

  constructor() {}

  ngOnInit(): void {}
}
