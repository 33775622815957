import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductListItem, BranchStockLevel } from '@idealsupply/ngclient-webservice-inventory';
import { BranchService } from 'lib-branches';
import { Subscription } from 'rxjs';
import { NgFor, DecimalPipe } from '@angular/common';

@Component({
  selector: 'product-list-item-stock',
  templateUrl: './product-list-item-stock.component.html',
  styleUrls: ['./product-list-item-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, DecimalPipe],
})
export class ProductListItemStockComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private currentBranchId?: string;

  @Input()
  private _item?: ProductListItem;

  protected totalStock: number = 0;

  @Input()
  public get item(): ProductListItem | undefined {
    return this._item;
  }
  public set item(value: ProductListItem | undefined) {
    this._item = value;
    this.totalStock =
      this.item?.stock?.reduce((acc, loc) => {
        return acc + (loc.stock || 0);
      }, 0) ?? 0;
  }

  @ViewChild('stockDialogTpl', { static: true })
  private dialogTpl?: TemplateRef<any>;

  public get currentBranch(): BranchStockLevel | undefined {
    if (this.item) {
      let branch: BranchStockLevel | undefined = undefined;
      let bId = this.currentBranchId;
      branch = this.item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = this.item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }

      return branch;
    }
    return undefined;
  }

  public get stock(): number {
    const stock = this.currentBranch?.stock || 0;
    return stock < 1 ? 0 : stock;
  }

  constructor(
    private branchService: BranchService,
    private changeRef: ChangeDetectorRef,
    private readonly dialogService: MatDialog,
  ) {
    this.subscription.add(
      branchService.currentBranchChange.subscribe((branchId) => {
        this.currentBranchId = branchId;
        this.changeRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public showPopup(): void {
    if (this.dialogTpl) {
      const dialogRef = this.dialogService.open(this.dialogTpl, {
        width: '95%',
        maxWidth: '45em',
      });
    }
  }
}
