import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PagedDataSource } from '../data/product-list-data-source';
import { MatCard } from '@angular/material/card';
import { ProductListItemComponent } from '../product-list-item/product-list-item.component';
import { NgTemplateOutlet, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgFor, ProductListItemComponent, NgIf, MatCard, MatPaginator],
})
export class ProductListComponent implements OnInit, CollectionViewer, OnDestroy {
  private _subs: Subscription = new Subscription();
  private _viewSubject?: BehaviorSubject<ListRange>;
  private _loading: boolean = true;

  @Input()
  public datasource?: PagedDataSource<ProductListItem>;
  private viewDataObs?: Observable<ProductListItem[]>;
  public viewData?: ProductListItem[];

  @Output()
  public loadingChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public itemClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  @Output()
  public thumbClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  @Output()
  public titleClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  public get loading(): boolean {
    return this._loading;
  }

  @ContentChild('toolTemplate', { static: true, read: TemplateRef })
  public toolsTemplate?: TemplateRef<{ $implicit: ProductListItem }>;
  @ContentChild('detailsTemplate', { static: true, read: TemplateRef })
  public detailsTemplate?: TemplateRef<{ $implicit: ProductListItem }>;
  @ContentChild('priceTemplate', { static: true, read: TemplateRef })
  public priceTemplate?: TemplateRef<{ $implicit: ProductListItem }>;

  private setLoading(value: boolean): void {
    if (this._loading !== value) {
      this._loading = value;
      this.elmRef.nativeElement.style.opacity = value ? '0.5' : '1';
      this.elmRef.nativeElement.style.pointerEvents = value ? 'none' : '';
      this.loadingChange.next(value);
    }
  }

  public get viewChange(): Observable<ListRange> {
    return this._viewSubject as Observable<ListRange>;
  }

  constructor(
    private readonly changeRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly elmRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this._viewSubject = new BehaviorSubject({
      start: this.datasource?.skip,
      end: this.datasource?.limit,
    } as ListRange);
    this.viewDataObs = this.datasource?.connect(this) as Observable<ProductListItem[]>;

    this._subs.add(
      this.datasource?.loading.subscribe((l) => {
        this.setLoading(l);
        this.changeRef.markForCheck();
      }),
    );

    this._subs.add(
      this.viewDataObs.subscribe((data) => {
        this.viewData = data;
        this.changeRef.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.datasource?.disconnect(this);
  }

  public pageChange(event: PageEvent): void {
    if (this.datasource) {
      //this.setLoading(true);
      this.datasource.skip = event.pageIndex * event.pageSize;
      this.datasource.limit = event.pageSize;
      this.router.navigate(['.'], {
        queryParams: {
          skip: this.datasource.skip,
          limit: this.datasource.limit,
        },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      });
    }
  }

  public handleItemClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.itemClick.emit(event);
  }

  public handleThumbClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.thumbClick.emit(event);
  }

  public handleTitleClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.titleClick.emit(event);
  }
}
