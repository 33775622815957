import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Product } from '@idealsupply/ngclient-webservice-inventory';
import { CartItem, ProductListItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { ProductUtil } from 'lib-products';
import Enumerable from 'linq';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { NgIf, AsyncPipe, DecimalPipe, CurrencyPipe } from '@angular/common';
@Component({
  selector: 'cart-item-price-calc',
  templateUrl: './cart-item-price-calc.component.html',
  styleUrls: ['./cart-item-price-calc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, DecimalPipe, CurrencyPipe],
})
export class CartItemPriceCalcComponent implements OnDestroy {
  private _destroy$$ = new Subject<void>();
  private _item$$ = new BehaviorSubject<undefined | CartItem | Product | ProductListItem>(undefined);

  private _cartItem$ = this._item$$.pipe(
    switchMap((item) => {
      if (item && (item as CartItem).branchQuantities) {
        return of(item as CartItem);
      } else if (item) {
        return this.cartService.itemsChange.pipe(
          map((ci) => ci.find((i) => i.lineCode === item.lineCode && i.partNumber === item.partNumber)),
        );
      }
      return of(undefined);
    }),
  );

  @Input()
  public get item(): undefined | CartItem | Product | ProductListItem {
    return this._item$$.value;
  }

  public set item(val: undefined | CartItem | Product | ProductListItem) {
    this._item$$.next(val);
  }

  @Input()
  public always?: boolean = false;

  public vm$ = this._cartItem$.pipe(
    map((item) => {
      if (item) {
        const unitCount = Enumerable.from(item.branchQuantities || []).sum((_) => _.quantity ?? 1) || 0;
        const total = ProductUtil.getPriceForQuantityInteger(item, unitCount);
        return {
          totalCount: unitCount * item.sellMultiple,
          priceTotal: total / 10000,
        };
      }
      return undefined;
    }),
    shareReplay(1),
    takeUntil(this._destroy$$),
  );

  constructor(private readonly cartService: CartService) {}

  ngOnDestroy(): void {
    this._destroy$$.next();
  }
}
