import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Cart, CartItem, CartSettings, ShoppingCartApi } from '@idealsupply/ngclient-webservice-shopping-cart';
import { CustomerService } from 'lib-customer';
import { ProductUtil } from 'lib-products';
import Enumerable from 'linq';
import { BehaviorSubject, EMPTY, firstValueFrom, fromEvent, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const GUEST_CUSTOMER = '000002';

const browserWindow = typeof window !== 'undefined' ? window : undefined;

export const CARTTYPE = new InjectionToken<string>('The shopping cart type', {
  providedIn: 'root',
  factory: () => 'IDEALLINK',
});

export interface CartItemUpdateEvent {
  lineCode: string;
  partNumber: string;
  quantity: number;
  price: number;
  item: CartItem;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartSubject: BehaviorSubject<Cart | undefined> = new BehaviorSubject<Cart | undefined>(undefined);
  private onCartPageShowingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _busy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _itemUpdate$$ = new Subject<CartItemUpdateEvent>();
  public readonly itemUpdate$ = this._itemUpdate$$.asObservable();

  private readonly _cartClear$$ = new Subject<CartItem[]>();
  public readonly cartClear$ = this._cartClear$$.asObservable();

  private readonly _cartRefreshEvent$: Observable<number> = browserWindow
    ? fromEvent(browserWindow, 'ideal-cart-refresh').pipe(
        map((evt) => (evt as CustomEvent).detail as number),
        tap(console.log),
      )
    : EMPTY;

  public get busy(): Observable<boolean> {
    return this._busy.asObservable();
  }

  constructor(
    @Inject(CARTTYPE) public readonly cartType: string,
    private readonly cartApi: ShoppingCartApi,
    private readonly customerService: CustomerService,
  ) {
    this.customerService.customerNumberChange.subscribe(async (customerNumber) => {
      if (customerNumber) {
        this.refresh();
      }
    });

    this._cartRefreshEvent$.subscribe(async (id) => {
      if (id === this.id) {
        this.refresh();
      }
    });
    this.refresh();
  }

  public async refresh(): Promise<void> {
    try {
      this._busy.next(true);
      const cart = await firstValueFrom(
        this.cartApi.getCart(this.cartType, this.customerService.customerNumber?.customerNumber || GUEST_CUSTOMER),
      );
      this.cartSubject.next(cart);
    } finally {
      this._busy.next(false);
    }
  }

  public get cartPageShowing(): boolean {
    return this.onCartPageShowingSubject.value;
  }

  public set cartPageShowing(value: boolean) {
    if (this.cartPageShowing !== value) {
      this.onCartPageShowingSubject.next(value);
    }
  }

  public get cartPageShowingChange(): Observable<boolean> {
    return this.onCartPageShowingSubject.asObservable();
  }

  public get cartSettings(): CartSettings | undefined {
    return this.cartSubject.value?.settings;
  }
  public get cartSettingsChange(): Observable<CartSettings | undefined> {
    return this.cartSubject.pipe(map((c) => c?.settings));
  }

  public get id(): number | undefined {
    return this.cartSubject.value?.id;
  }

  public get idChange(): Observable<number | undefined> {
    return this.cartSubject.pipe(map((c) => c?.id));
  }

  public get cart(): Cart | undefined {
    return this.cartSubject.value;
  }

  public get cartChange(): Observable<Cart | undefined> {
    return this.cartSubject;
  }

  public get items(): CartItem[] {
    return this.cartSubject.value?.items || [];
  }

  public get itemsChange(): Observable<CartItem[]> {
    return this.cartSubject.pipe(map((c) => c?.items || []));
  }

  public get subtotal(): number {
    var cartItems = this.cartSubject.value?.items;

    if (!cartItems?.length) {
      return 0;
    }

    const subtotal = Enumerable.from(cartItems)
      .selectMany((p) => Enumerable.from(p.branchQuantities || []).select((i) => ProductUtil.getPriceForQuantityInteger(p, i.quantity)))
      .sum();
    return subtotal / 10000;
  }

  public get feeTotal(): number {
    var cartItems = this.cartSubject.value?.items;

    if (!cartItems?.length) {
      return 0;
    }

    const feeTotal = Enumerable.from(cartItems)
      .selectMany((p) => Enumerable.from(p.branchQuantities || []).select((i) => ProductUtil.getFeesForQuantity(p, i.quantity)))
      .sum();
    return feeTotal;
  }

  public get noSaleSubtotal(): number {
    var cartItems = this.cartSubject.value?.items;

    if (!cartItems?.length) {
      return 0;
    }

    const subtotal = Enumerable.from(cartItems)
      .selectMany((p) =>
        Enumerable.from(p.branchQuantities || []).select((i) => ProductUtil.getPriceForQuantityInteger(p, i.quantity, true)),
      )
      .sum();
    return subtotal / 10000;
  }

  public get savings(): number {
    return Math.max(this.noSaleSubtotal - this.subtotal, 0);
  }

  public get points(): number {
    /*if ((this.cartSettings?.pointRatio || 0) > 0) {
      return Math.max(this.subtotal * (this.cartSettings?.pointRatio || 0), 0);
    }*/
    return 0;
  }

  public get lastUpdate(): Date | undefined {
    return !!this.cartSubject.value?.updated ? new Date(this.cartSubject.value?.updated) : undefined;
  }
  public get lastUpdateChange(): Observable<Date | undefined> {
    return this.cartSubject.pipe(map((c) => (!!c?.updated ? new Date(c?.updated) : undefined)));
  }

  public get created(): Date | undefined {
    return !!this.cartSubject.value?.created ? new Date(this.cartSubject.value?.created) : undefined;
  }

  public get createdChanged(): Observable<Date | undefined> {
    return this.cartSubject.pipe(map((c) => (!!c?.created ? new Date(c?.created) : undefined)));
  }

  public async updateItemQuantity(lineCode: string, partNumber: string, quantity: number, branchId?: string): Promise<void> {
    try {
      this._busy.next(true);
      const oprod = this.findItem(lineCode, partNumber);

      const cart = await firstValueFrom(
        this.cartApi.addItemToCart(this.cartType, this.customerService.customerNumber?.customerNumber || GUEST_CUSTOMER, {
          lineCode,
          partNumber,
          branchQuantities: [
            {
              quantity,
              branchId,
            },
          ],
        }),
      );
      this.cartSubject.next(cart);

      const prod = this.findItem(lineCode, partNumber);
      const quant = Enumerable.from(prod?.branchQuantities ?? []).sum((v) => v.quantity);

      const item = prod ?? oprod!;

      this._itemUpdate$$.next({
        lineCode,
        partNumber,
        quantity: quant,
        item,
        price: item.pricing[0].value,
      });
    } finally {
      this._busy.next(false);
    }
  }

  private findItem(lineCode: string, partNumber: string) {
    return this.items.find((i) => i.lineCode === lineCode && i.partNumber === partNumber);
  }

  public async addItemQuantity(lineCode: string, partNumber: string, quantity: number, branchId?: string): Promise<void> {
    const currentItem = this.findItem(lineCode, partNumber);
    const branch = currentItem?.branchQuantities?.find((b) => b.branchId === (branchId ?? null));
    if (!branch) {
      await this.updateItemQuantity(lineCode, partNumber, quantity, branchId);
      return;
    }
    let q = Math.max(branch.quantity + quantity, 0);
    await this.updateItemQuantity(lineCode, partNumber, q, branchId);
  }

  public async clearCart(): Promise<void> {
    try {
      this._busy.next(true);

      const cart = await firstValueFrom(
        this.cartApi.emptyCart(this.cartType, this.customerService.customerNumber?.customerNumber || GUEST_CUSTOMER),
      );

      this._cartClear$$.next(this.items?.slice() ?? []);

      this.cartSubject.next(cart);
    } finally {
      this._busy.next(false);
    }
  }

  public async searchSavedCarts(customerNumber?: string, skip?: number, limit?: number) {
    return await firstValueFrom(this.cartApi.getSavedCarts(customerNumber, skip ?? 0, limit ?? -1));
  }

  public getSavedCart(cartId: number) {
    return this.cartApi.getSavedCart(cartId);
  }

  public async saveCartAs(name: string) {
    if (this.cart) {
      return await firstValueFrom(this.cartApi.createSavedCart(this.cart.id, name));
    }
    return undefined;
  }

  public async mergeToSavedCart(savedCartId: number) {
    if (this.cart) {
      return await firstValueFrom(this.cartApi.addToSavedCart(savedCartId, 'Merge', this.cart.id));
    }
    return undefined;
  }

  public async replaceSavedCart(savedCartId: number) {
    if (this.cart) {
      return await firstValueFrom(this.cartApi.addToSavedCart(savedCartId, 'Replace', this.cart.id));
    }
    return undefined;
  }

  public async addSavedToCart(savedCartId: number, multiplier: number) {
    await firstValueFrom(
      this.cartApi.convertToShoppingCart(
        this.cartType,
        this.customerService.customerNumber?.customerNumber || GUEST_CUSTOMER,
        savedCartId,
        multiplier,
      ),
    );
    await this.refresh();
  }

  public async deleteSavedCart(savedCartId: number) {
    await firstValueFrom(this.cartApi.deleteSavedCart(savedCartId));
  }
}
