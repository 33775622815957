import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, HostBinding, Input, OnDestroy, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { Product, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { BranchStockLevel, CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import linq from 'linq';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { CartQuantityFieldComponent } from '../cart-quantity-field/cart-quantity-field.component';
import { CartService } from '../cart.service';

interface CartAddRemoveButtonComponentViewModel {
  product: CartItem | Product | ProductListItem | undefined;
  hasQuantity: boolean;
  itemQuantity: number;
  shouldCall: boolean;
}

@Component({
  selector: 'cart-add-remove-button',
  templateUrl: './cart-add-remove-button.component.html',
  styleUrls: ['./cart-add-remove-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButton, CartQuantityFieldComponent, AsyncPipe],
  host: {
    '[attr.state]': 'state()',
  },
})
export class CartAddRemoveButtonComponent implements OnDestroy {
  private _destroy$$ = new Subject<void>();

  private _item$$ = new BehaviorSubject<CartItem | Product | ProductListItem | undefined>(undefined);

  private _defaultQuantity: number | undefined;

  @Input()
  public get defaultQuantity(): number {
    return this._defaultQuantity || 1;
  }
  public set defaultQuantity(value: number | undefined) {
    this._defaultQuantity = value;
  }

  @Input()
  public shouldCallOverride?: boolean = undefined;

  @Input()
  public get item(): CartItem | Product | ProductListItem | undefined {
    return this._item$$.value;
  }

  public set item(value: CartItem | Product | ProductListItem | undefined) {
    this._item$$.next(value);
  }

  private _cartItem$ = combineLatest([this._item$$, this.cartService.itemsChange]).pipe(
    map(([item, cartItems]) => cartItems.find((i) => i.lineCode === item?.lineCode && i.partNumber === item?.partNumber)),
  );

  public vm$ = this._cartItem$.pipe(
    map((item) => {
      const vm: CartAddRemoveButtonComponentViewModel = {
        product: item ?? this.item,
        hasQuantity: false,
        itemQuantity: 0,
        shouldCall: false,
      };

      vm.itemQuantity = (item?.branchQuantities ?? [])[0]?.quantity ?? 0;
      vm.hasQuantity = vm.itemQuantity > 0;
      vm.shouldCall = this.shouldCallOverride ?? this.calcShouldCall(vm.product);

      return vm;
    }),
    tap((vm) => {
      this.cartItemChange.emit(vm.product);
      this.hasQuantity = vm.hasQuantity;
    }),
    takeUntil(this._destroy$$),
  );

  @Output()
  public cartItemChange: EventEmitter<CartItem | undefined> = new EventEmitter();

  @Output()
  public hasQuantityChange = this.vm$.pipe(
    map((i) => i.hasQuantity),
    distinctUntilChanged(),
    takeUntil(this._destroy$$),
  );

  @HostBinding('class.hasQuantity')
  public hasQuantity: boolean = false;

  public get busy$() {
    return this.cartService.busy;
  }

  protected hasQuantitySignal = toSignal(this.hasQuantityChange, { initialValue: false });
  protected shouldCallSignal = toSignal(this.vm$.pipe(map((v) => v.shouldCall)), { initialValue: false });
  protected busySignal = toSignal(this.busy$, { initialValue: false });
  protected addSignal = signal<'add' | 'adding' | 'added'>('add');
  protected state = computed(() => {
    if (this.shouldCallSignal()) {
      return 'call';
    }
    if (this.addSignal() !== 'add') {
      return this.addSignal();
    }
    if (this.busySignal()) {
      return this.hasQuantitySignal() ? 'busy-edit' : 'busy-add';
    }
    return this.hasQuantitySignal() ? 'edit' : 'add';
  });

  constructor(
    private readonly cartService: CartService,
    private readonly branchService: BranchService,
  ) {}

  private calcShouldCall(item: CartItem | Product | ProductListItem | undefined): boolean {
    return (
      !this.calcAllowOrders(item) || (!this.calcAllowBackOrders(item) && this.calcTotalAvailable(item) <= 0) || !this.calcHasPrice(item)
    );
  }

  private calcHasPrice(item: CartItem | Product | ProductListItem | undefined): boolean {
    return !!item?.pricing?.[0]?.value;
  }

  private calcAllowOrders(item: CartItem | Product | ProductListItem | undefined): boolean {
    return item?.allowOrders || false;
  }

  private calcAllowBackOrders(item: CartItem | Product | ProductListItem | undefined): boolean {
    return item?.allowBackorders || false;
  }
  private calcTotalAvailable(item: CartItem | Product | ProductListItem | undefined): number {
    return item?.stock ? linq.from(item?.stock).sum((_) => _.stock || 0) : 0;
  }

  public ngOnDestroy(): void {
    this._destroy$$.next();
  }

  public async add(item: CartItem | Product | ProductListItem | undefined): Promise<void> {
    if (item) {
      const branchQuantities = (item && 'branchQuantities' in item ? item.branchQuantities : undefined) ?? [];
      const amount = linq.from(branchQuantities).sum((_) => _.quantity) || this.defaultQuantity;
      const allowBackOrders = this.calcAllowBackOrders(item);
      const totalAvailable = this.calcTotalAvailable(item);
      if (allowBackOrders || amount <= totalAvailable) {
        this.addSignal.set('adding');
        await this.cartService.updateItemQuantity(item.lineCode, item.partNumber, amount, this.getDefaultBranch(item));
        this.addSignal.set('added');
        setTimeout(() => {
          this.addSignal.set('add');
        }, 1000);
      }
    }
  }

  private getDefaultBranch(item: CartItem | Product | ProductListItem): string {
    if (item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.branchService.currentBranch;
      branch = item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }
      return branch?.branch as string;
    }
    return '';
  }
}
