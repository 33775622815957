import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductListItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { PagedDataSource, ProductListComponent } from 'lib-products';
import { CartItemFeeCalcComponent } from '../../cart/cart-item-fee-calc/cart-item-fee-calc.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { CartItemStockComponent } from '../../cart/cart-item-stock/cart-item-stock.component';
import { CartAddRemoveButtonComponent } from '../../cart/cart-add-remove-button/cart-add-remove-button.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'product-purchase-list',
  templateUrl: './product-purchase-list.component.html',
  styleUrls: ['./product-purchase-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProductListComponent, CartAddRemoveButtonComponent, CartItemStockComponent, NgIf, RouterLink, CartItemFeeCalcComponent],
})
export class ProductPurchaseListComponent implements OnInit {
  @Input()
  public datasource?: PagedDataSource<ProductListItem>;
  @Output()
  public loadingChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public itemClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();
  @Output()
  public thumbClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();
  @Output()
  public titleClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public handleItemClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.itemClick.emit(event);
  }
  public handleThumbClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.thumbClick.emit(event);
  }
  public handleTitleClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>): void {
    this.titleClick.emit(event);
  }
}
