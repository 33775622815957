import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Signal } from '@angular/core';
import { DrawerComponent, DrawerGroupComponent, NamedRenderInSource, RenderInTargetComponentBase } from 'common-ui';
import { AppShellStore } from '../../state/app-shell.store';

@Component({
  selector: 'ideal-app-header-drawers',
  standalone: true,
  imports: [DrawerGroupComponent, DrawerComponent, NgTemplateOutlet],
  templateUrl: './app-header-drawers.component.html',
  styleUrl: './app-header-drawers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderDrawersComponent extends RenderInTargetComponentBase {
  private readonly appShellStore = inject(AppShellStore);

  protected readonly drawers = this.renderItems as Signal<NamedRenderInSource<undefined>[]>;

  protected readonly selectedDrawerIndex = computed(() => {
    const drawers = this.drawers();
    const drawerName = this.appShellStore.headerDrawerOpened();
    return drawerName ? drawers.findIndex((drawer) => drawer.renderInSourceName === drawerName) : -1;
  });

  public override get context() {
    return undefined;
  }
}
