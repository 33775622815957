<div class="instock">{{ stock | number }} in stock</div>
<div class="branch">{{ currentBranch?.name }}</div>
@if (totalStock === 0) {
  Available for Backorder
} @else {
  <a (click)="showPopup(); $event.stopPropagation()">Check other stores</a>
}

<ng-template #stockDialogTpl>
  <div class="stock-list">
    <div class="branch" *ngFor="let branch of item?.stock">
      <div class="name">{{ branch.name }}</div>
      <div class="stock">{{ branch.stock }}</div>
    </div>
  </div>
</ng-template>
