import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Product } from '@idealsupply/ngclient-webservice-inventory';
import { CartItem, ProductListItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, AsyncPipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'cart-item-fee-calc',
  templateUrl: './cart-item-fee-calc.component.html',
  styleUrls: ['./cart-item-fee-calc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, AsyncPipe, CurrencyPipe],
})
export class CartItemFeeCalcComponent implements OnDestroy {
  private _destroy$$ = new Subject<void>();
  private _item$$ = new BehaviorSubject<undefined | CartItem | Product | ProductListItem>(undefined);

  private _quantity$ = combineLatest([this._item$$, this.cartService.itemsChange]).pipe(
    map(([item, items]) => {
      if (item) {
        const cItem = items.find((i) => i.lineCode === item.lineCode && i.partNumber === item.partNumber);
        return (
          cItem?.branchQuantities?.reduce((sum, bQty) => sum + (this.useQuantity ? bQty.quantity * (item.sellMultiple || 1) : 1), 0) ?? 1
        );
      }
      return 0;
    }),
  );

  @Input()
  public get item(): undefined | CartItem | Product | ProductListItem {
    return this._item$$.value;
  }

  public set item(val: undefined | CartItem | Product | ProductListItem) {
    this._item$$.next(val);
  }

  @Input()
  public useQuantity: boolean = false;

  public vm$ = combineLatest([this._item$$, this._quantity$]).pipe(
    map(([item, quantity]) => {
      return { quantity, fees: item?.fees ?? [] };
    }),
    takeUntil(this._destroy$$),
  );

  constructor(private readonly cartService: CartService) {}

  ngOnDestroy(): void {
    this._destroy$$.next();
  }
}
