<ng-container *ngIf="!itemQuantity">
  <div class="add-cart">
    <button mat-flat-button (click)="setQuantity(1)" [disabled]="(busy | async) ?? false">Add to Cart</button>
  </div>
</ng-container>
<ng-container *ngIf="itemQuantity">
  <div class="edit-cart">
    <div>
      <mat-form-field>
        <mat-label>Quantity in Cart</mat-label>
        <input
          #q
          type="number"
          [formControl]="quantityField"
          matInput
          (change)="setQuantity(+q.value || 1); q.value = '' + (+q.value || 1)"
          [disabled]="(busy | async) ?? false"
        />
        <mat-hint>
          <ng-container *ngIf="(item?.sellMultiple || 0) > 1">x {{ item?.sellMultiple | number }} =</ng-container>
          {{ (item?.sellMultiple || 1) * (+q.value || 0) | number }} total
        </mat-hint>
        <mat-error *ngIf="quantityField.hasError('available-exceeded')">Only {{ this.totalAvailable | number }} Available</mat-error>
        <mat-error *ngIf="quantityField.hasError('min')">Must be 1 or more</mat-error>
        <mat-error *ngIf="quantityField.hasError('max')">Cannot be more than {{ quantityField.getError("max").max | number }}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <button mat-icon-button color="warn" (click)="deleteItem()" [disabled]="(busy | async) ?? false">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
