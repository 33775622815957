<ng-container *ngTemplateOutlet="paginator"></ng-container>
<div>
  <product-list-item
    *ngFor="let item of viewData"
    [item]="item"
    (itemClick)="handleItemClick($event)"
    (thumbClick)="handleThumbClick($event)"
    (titleClick)="handleTitleClick($event)"
  >
    <ng-container tool *ngIf="toolsTemplate">
      <ng-container *ngTemplateOutlet="toolsTemplate; context: { $implicit: item }"></ng-container>
    </ng-container>
    <ng-container detail *ngIf="detailsTemplate">
      <ng-container *ngTemplateOutlet="detailsTemplate; context: { $implicit: item }"></ng-container>
    </ng-container>
    <ng-container price *ngIf="priceTemplate">
      <ng-container *ngTemplateOutlet="priceTemplate; context: { $implicit: item }"></ng-container>
    </ng-container>
  </product-list-item>
</div>
<ng-container *ngTemplateOutlet="paginator"></ng-container>

<ng-template #paginator>
  <mat-card *ngIf="datasource?.total ?? 0 > 0">
    <mat-paginator
      [pageIndex]="(datasource?.skip ?? 0) / (datasource?.limit || 1)"
      [length]="datasource?.total"
      [pageSize]="datasource?.limit || 10"
      [pageSizeOptions]="[10, 25, 100]"
      (page)="pageChange($event)"
    >
    </mat-paginator>
  </mat-card>
</ng-template>
