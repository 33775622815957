import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { withActivePopover } from './withActivePopover';

export interface ApplicationHeaderDrawerSlice {
  headerDrawerOpened: string | null;
}

export function withApplicationHeaderDrawers() {
  return signalStoreFeature(
    withState<ApplicationHeaderDrawerSlice>({
      headerDrawerOpened: null,
    }),
    withActivePopover(),
    withComputed((state) => ({
      headerDrawerIsOpen: computed(() => state.headerDrawerOpened() !== null),
    })),
    withMethods((state) => {
      const open = (drawer: string) => {
        patchState(state, { headerDrawerOpened: drawer, popoverActive: 'header' });
      };

      const close = (drawer?: string) => {
        if (!drawer || state.headerDrawerOpened() === drawer) {
          patchState(state, { headerDrawerOpened: null });
        }
      };
      const toggle = (drawer: string) => (state.headerDrawerOpened() === drawer ? close(drawer) : open(drawer));

      return {
        headerDrawerOpen: open,
        headerDrawerToggle: toggle,
        headerDrawerClose: close,
      };
    }),
  );
}
