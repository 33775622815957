import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DrawerComponent } from 'common-ui';
import { NgTemplateOutlet } from '@angular/common';
import { AppActionsMainRenderTargetComponent } from '../../render-targets/app-actions-main/app-actions-main-render-target.component';
import { AppActionsSecondaryRenderTargetComponent } from '../../render-targets/app-actions-secondary/app-actions-secondary-render-target.component';
import { AppToolbarRenderTargetComponent } from '../../render-targets/app-toolbar/app-toolbar-render-target.component';
import { AppShellStore } from '../../state/app-shell.store';
import { AppHeaderLogoComponent } from './components/app-header-logo/app-header-logo.component';
import { AppHeaderMenuButtonComponent } from './components/app-header-menu-button/app-header-menu-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppHeaderHandleComponent } from './components/app-header-handle/app-header-handle.component';

@Component({
  selector: 'ideal-app-header',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    DrawerComponent,
    MatToolbarModule,
    // AppHeaderWedgeContainerComponent,
    AppHeaderHandleComponent,
    MatButtonModule,
    MatIconModule,
    AppHeaderMenuButtonComponent,
    AppHeaderLogoComponent,
    AppToolbarRenderTargetComponent,
    AppActionsMainRenderTargetComponent,
    AppActionsSecondaryRenderTargetComponent,
  ],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
  private readonly _store = inject(AppShellStore);

  protected menuIsOpen = this._store.menuIsOpen;

  protected toggleMenu() {
    this._store.menuToggle();
  }

  protected readonly headerHidden = this._store.hideHeaderOnScrollIsHidden;
  protected readonly hideHeaderOnScrollEnabled = this._store.hideHeaderOnScrollEnabled;

  protected toggleHeaderHidden() {
    this._store.toggleHeader();
  }
}
