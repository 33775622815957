<ng-form [formGroup]="form">
  <mat-form-field>
    <mat-label>Part List</mat-label>
    <input
      type="text"
      placeholder="Select a part list to filter with"
      aria-label="Select a part list to filter with"
      matInput
      [matAutocomplete]="auto"
      xchangex="optionSelected()"
      formControlName="selectFilter"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      [autoActiveFirstOption]="true"
      xoptionSelectedx="optionSelected($event.option.value)"
      [displayWith]="displayFn"
    >
      <mat-option
        *ngFor="let option of filteredSavedLists$ | async"
        [value]="option"
        xclass.selectedx="selectedListControl.value === option"
        >{{ option.name }}</mat-option
      >
    </mat-autocomplete>
  </mat-form-field>
</ng-form>

<!--
  <pre>{{ form.value | json }}</pre>
  <pre>{{ filteredSavedLists$ | async | json }}</pre>


<ng-container *ngIf="(data$ | async)?.length; else noLists">
  <mat-form-field>
    <mat-label>Part List</mat-label>
    <input
      type="text"
      placeholder="Select a part list to filter with"
      aria-label="Select a part list to filter with"
      matInput
      [formControl]="filterTextControl"
      [matAutocomplete]="auto"
      (blur)="filterTextControl.setValue(selectedListControl.value ?? '')"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      [autoActiveFirstOption]="true"
      (optionSelected)="optionSelected($event.option.value)"
      [displayWith]="displayFn"
    >
      <! --
      <nav mat-tab-nav-bar color="primary">
        <a mat-tab-link *ngFor="let type of types$ | async" [active]="type$.value == type.id" (click)="type$.next(type.id)">{{ type.name }}</a>
      </nav>
    -- >
      <mat-option *ngIf="!(options$ | async)?.length" [value]="null">
        No part lists match your search
      </mat-option>

      <mat-option
        *ngFor="let option of options$ | async; trackBy: trackById"
        [value]="option"
        [class.selected]="selectedListControl.value === option"
      >
        <mat-checkbox
          class="example-margin"
          [checked]="selectedListControl.value === option"
          color="accent"
        >
          {{ option.name }}
        </mat-checkbox>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>

<ng-template #noLists>
  <mat-form-field floatLabel="always" >
    <mat-label>Part List</mat-label>
    <input
      type="text"
      placeholder="You have no saved part lists"
      aria-label="You have no saved part lists"
      matInput
      disabled
    />
  </mat-form-field>
</ng-template>



-->
