import { computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { signalStore, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { filter } from 'rxjs';
import { AppShellStore } from '../../../state/app-shell.store';
import { IdealSearchFieldPlaceholder } from '../app-search-field/IdealSearchFieldPlaceholder';
import { SearchService } from './SearchService';

export const AppSearchComponentStore = signalStore(
  withComputed(() => {
    const appStore = inject(AppShellStore);
    const searchService = inject(SearchService, { optional: true });
    const placeholderText = signal(inject(IdealSearchFieldPlaceholder, { optional: true }) ?? '');

    const hasSearchResults = computed(() => (searchService?.searchResultCount() ?? 0) > 0);
    const resultsOpen = computed(() => appStore.headerDrawerOpened() === 'search-results');

    return {
      hasSearch: computed(() => !!searchService),
      searchState: searchService ? searchService.searchState : signal(null),
      searchError: searchService ? searchService.searchError : signal(null),
      searchQuery: searchService ? searchService.searchQuery : signal(''),
      searchResults: searchService ? searchService.searchResults : signal(null),
      searchResultCount: searchService ? searchService.searchResultCount : signal(0),
      placeholderText,
      hasSearchResults,
      resultsOpen,
    };
  }),
  withMethods((state) => {
    const appStore = inject(AppShellStore);
    const searchService = inject(SearchService, { optional: true });
    return {
      search: (value: string) => searchService?.search(value),
      openResults: () => appStore.headerDrawerOpen('search-results'),
      closeResults: () => appStore.headerDrawerClose('search-results'),
    };
  }),
  withHooks((state) => {
    const router = inject(Router);
    return {
      onInit() {
        const searchQuery = atob(sessionStorage.getItem('AppSearchComponentStore_searchQuery') ?? '');
        if (searchQuery) {
          state.search(searchQuery);
        }
        effect(() => {
          const query = state.searchQuery();
          sessionStorage.setItem('AppSearchComponentStore_searchQuery', btoa(query));
        });
        router.events
          .pipe(
            filter((e) => e instanceof NavigationStart),
            takeUntilDestroyed(),
          )
          .subscribe(() => {
            state.closeResults();
          });
      },
    };
  }),
);
