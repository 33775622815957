import { SavedListSearchItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { IPagedResult, PagedDataSource } from 'lib-products';
import { SavedListsService } from '../saved-lists.service';
import { firstValueFrom } from 'rxjs';

export interface ISavedListsFilter {
  customerNumber?: string;
  listType?: 1 | 2;
}

export class SavedListsDataSource extends PagedDataSource<SavedListSearchItem> {
  private _filter: ISavedListsFilter = {};

  constructor(
    private readonly service: SavedListsService,
    skip: number = 0,
    limit: number = 10,
  ) {
    super(skip, limit);
  }

  private cleanFilter(data: ISavedListsFilter): ISavedListsFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const val = (data as any)[key];
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  public get customerNumber(): string | undefined {
    return this._filter.customerNumber;
  }

  public set customerNumber(value: string | undefined) {
    value = value || undefined;
    if (this.customerNumber !== value) {
      this.changed = true;
      this._filter.customerNumber = value;
      this.invalidateResults();
    }
  }
  public get listType(): 1 | 2 | undefined {
    return this._filter.listType;
  }

  public set listType(value: 1 | 2 | undefined) {
    if (this.listType !== value) {
      this.changed = true;
      this._filter.listType = value;
      this.invalidateResults();
    }
  }

  public get filter(): ISavedListsFilter {
    return this._filter;
  }

  public set filter(value: ISavedListsFilter) {
    value ??= {};
    if (JSON.stringify(this.filter) !== JSON.stringify(value)) {
      this.changed = true;
      this._filter = value;
      this.invalidateResults();
    }
  }

  protected async update(): Promise<IPagedResult<SavedListSearchItem>> {
    const f = this._filter ?? {};
    this.cleanFilter(f);

    const result = await firstValueFrom(this.service.search(this.customerNumber, this.listType, this.skip, this.limit));
    return result!;
  }

  public override initialize(value?: ISavedListsFilter): Promise<this> {
    this._filter = value ?? {};
    return super.initialize();
  }
}
