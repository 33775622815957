import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { ProductUtil } from '../data/ProductUtil';
import { NgIf, DecimalPipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'product-list-item-price',
  templateUrl: './product-list-item-price.component.html',
  styleUrls: ['./product-list-item-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DecimalPipe, CurrencyPipe],
})
export class ProductListItemPriceComponent {
  @Input()
  public item?: ProductListItem;

  @Input()
  public priceOverride?: number;

  @Input()
  public pricePerOverride?: number;

  @Input()
  public wasPriceOverride?: number;

  constructor() {}

  public get price(): number {
    return this.priceOverride ?? (this.item ? ProductUtil.getSalesPrice(this.item) : 0);
  }

  public get pricePer(): number {
    return this.pricePerOverride ?? (this.item ? ProductUtil.getPricePer(this.item) : 1) ?? 1;
  }

  public get wasPrice(): number {
    if (this.wasPriceOverride !== undefined) {
      return this.wasPriceOverride > this.price ? this.wasPriceOverride : 0;
    }
    if (this.item) {
      const base = ProductUtil.getBasePrice(this.item);
      const price = this.price;
      return base > price ? base : 0;
    }
    return 0;
  }
}
