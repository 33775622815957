import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Product } from '@idealsupply/ngclient-webservice-inventory';
import { BranchStockLevel, CartItem, ProductListItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import linq from 'linq';
import { Subscription } from 'rxjs';
import { CartService } from '../cart.service';
import { CartQuantityFieldComponent } from '../cart-quantity-field/cart-quantity-field.component';
@Component({
  selector: 'cart-edit-quantity',
  templateUrl: './cart-edit-quantity.component.html',
  styleUrls: ['./cart-edit-quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CartQuantityFieldComponent],
})
export class CartEditQuantityComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();

  @Input()
  public item?: CartItem | Product | ProductListItem;
  public cartItem?: CartItem;

  @Output()
  public cartItemChange: EventEmitter<CartItem | undefined> = new EventEmitter();

  @HostBinding('class.hasQuantity')
  public get hasQuantity(): boolean {
    return (this.cartItem?.branchQuantities?.length || 0) > 0;
  }

  constructor(
    private readonly cartService: CartService,
    private readonly branchService: BranchService,
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.cartService.itemsChange.subscribe((items) => {
        const ci = items.find((i) => i.lineCode === this.item?.lineCode && i.partNumber === this.item?.partNumber);
        if (this.cartItem !== ci) {
          this.cartItem = ci;
          this.cartItemChange.emit(ci);
          this.changeRef.markForCheck();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public async add(): Promise<void> {
    if (this.item) {
      this.cartService.updateItemQuantity(
        this.item.lineCode as string,
        this.item.partNumber,
        linq.from(this.cartItem?.branchQuantities || []).sum((_) => _.quantity) || 1,
        this.getDefaultBranch(this.item),
      );
    }
  }

  public async remove(): Promise<void> {
    if (this.item) {
      this.cartService.updateItemQuantity(this.item.lineCode as string, this.item.partNumber, 0, this.getDefaultBranch(this.item));
    }
  }

  private getDefaultBranch(item: CartItem | Product | ProductListItem): string {
    if (item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.branchService.currentBranch;
      branch = item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }

      return branch?.branch as string;
    }
    return '';
  }
}
