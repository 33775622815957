import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { withActivePopover } from './withActivePopover';

export interface ApplicationSideDrawerSlice {
  sideDrawerOpened: string | null;
}

export function withApplicationSideDrawers() {
  return signalStoreFeature(
    withState<ApplicationSideDrawerSlice>({
      sideDrawerOpened: null,
    }),
    withActivePopover(),
    withComputed((state) => ({
      sideDrawerIsOpen: computed(() => state.sideDrawerOpened() !== null),
    })),
    withMethods((state) => {
      const open = (drawer: string) => {
        patchState(state, { sideDrawerOpened: drawer, popoverActive: 'side' });
      };

      const close = (drawer?: string) => {
        if (!drawer || state.sideDrawerOpened() === drawer) {
          patchState(state, { sideDrawerOpened: null });
        }
      };
      const toggle = (drawer: string) => (state.sideDrawerOpened() === drawer ? close(drawer) : open(drawer));

      return {
        sideDrawerOpen: open,
        sideDrawerToggle: toggle,
        sideDrawerClose: close,
      };
    }),
  );
}
