import { CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { IPagedResult, PagedDataSource } from 'lib-products';
import linq from 'linq';
import { CartService } from '../cart.service';

export class CartItemListDataSource extends PagedDataSource<CartItem> {
  constructor(
    private readonly cartService: CartService,
    skip: number = 0,
    limit: number = 10,
  ) {
    super(skip, limit);
    this.subs.add(
      cartService.itemsChange.subscribe((_) => {
        this.changed = true;
        this.invalidateResults();
      }),
    );
  }

  protected update(): Promise<IPagedResult<CartItem>> {
    return new Promise((res, rej) => {
      try {
        let q = linq.from(this.cartService.items);
        if (this.skip > 0) {
          q = q.skip(this.skip);
        }
        if (this.limit > 0) {
          q = q.take(this.limit);
        }
        const r = q.toArray();
        res({
          skip: this.skip,
          limit: this.limit,
          total: this.cartService.items?.length || 0,
          data: r,
        });
      } catch (e) {
        rej(e);
      }
    });
  }
}
