import { computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { signalStore, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { filter, skip } from 'rxjs';
import { withApplicationHeader } from './withApplicationHeader';
import { withApplicationMenu } from './withApplicationMenu';
import { withApplicationSideDrawers } from './withApplicationSideDrawers';
import { withFeatureImportComponents } from './withFeatureImportComponents';
import { withHasSearchbar } from './withHasSearchbar';
import { withSessionPersist } from './withSessionPersist';

export const AppShellStore = signalStore(
  { providedIn: 'root' },
  withFeatureImportComponents(),
  withHasSearchbar(),
  withApplicationHeader(),
  withApplicationMenu(),
  withApplicationSideDrawers(),
  withComputed((state) => ({
    popoverIsOpen: computed(() => state.headerDrawerIsOpen() || (!state.menuIsInline() && state.menuIsOpen()) || state.sideDrawerIsOpen()),
  })),
  withMethods((state) => {
    const closeAll = () => {
      state.headerDrawerClose();
      state.sideDrawerClose();
      if (!state.menuIsInline()) {
        state.menuClose();
      }
    };
    return {
      popoversCloseAll: closeAll,
    };
  }),
  withHooks((state) => {
    const router = inject(Router);

    const closeOtherDrawers = () => {
      const popoverActive = state.popoverActive();
      if (popoverActive === 'menu') {
        state.headerDrawerClose();
        state.sideDrawerClose();
      } else if (popoverActive === 'header') {
        state.menuClose();
        state.sideDrawerClose();
      } else if (popoverActive === 'side') {
        state.menuClose();
        state.headerDrawerClose();
      }
      state.setPopoverActive(null);
    };

    return {
      onInit: () => {
        effect(closeOtherDrawers, { allowSignalWrites: true });
        router.events
          .pipe(
            filter((e) => e instanceof NavigationEnd || e instanceof NavigationError),
            skip(1),
            takeUntilDestroyed(),
          )
          .subscribe((event) => state.popoversCloseAll());
      },
    };
  }),
  withSessionPersist('AppShellComponentStore_State', ['headerDrawerOpened', 'sideDrawerOpened', 'menuOpened']),
);
