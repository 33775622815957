<form [formGroup]="form">
  <div mat-dialog-title>Save cart as parts list</div>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Save Part List</mat-label>
      <input
        type="text"
        placeholder="Name of part list"
        matInput
        #savePartsListAs
        autocomplete="off"
        formControlName="savePartsListAs"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let partList of filteredCustomerPartLists$ | async" [value]="partList">
          {{ partList.name }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="form.get('savePartsListAs')?.hasError('duplicate')"> A part list with that name already exists. </mat-error>
      <mat-error *ngIf="form.get('savePartsListAs')?.hasError('minlength')">
        Name is to short. {{ savePartsListAs.value.length }} / 3
      </mat-error>
      <mat-error *ngIf="form.get('savePartsListAs')?.hasError('maxlength')">
        Name is to long. {{ savePartsListAs.value.length }} / 50
      </mat-error>
      <mat-error *ngIf="form.get('savePartsListAs')?.hasError('required')"> Name is required. </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <div class="save-buttons">
      <ng-container *ngIf="selectedListId$ | async as selectedListId">
        <button
          mat-stroked-button
          [mat-dialog-close]="{ action: 'replace', value: selectedListId }"
          color="accent"
          [disabled]="!isValidUpdate"
        >
          Replace List
        </button>

        <button
          mat-stroked-button
          [mat-dialog-close]="{ action: 'merge', value: selectedListId }"
          color="accent"
          [disabled]="!isValidUpdate"
        >
          Add Items
        </button>
      </ng-container>
      <button
        mat-stroked-button
        [mat-dialog-close]="{ action: 'new', value: savePartsListAs.value }"
        color="accent"
        [disabled]="form.invalid"
      >
        Create
      </button>
    </div>
  </mat-dialog-actions>
</form>
