import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SavedListSearchItem } from '@idealsupply/ngclient-webservice-shopping-cart';

import { BehaviorSubject, combineLatest, concat, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { SavedListsService } from '../../saved-lists.service';
import { MatOption } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

type SearchableSavedListSearchItem = SavedListSearchItem & {
  normalizedName: string;
};

@Component({
  selector: 'cart-saved-list-select',
  templateUrl: './saved-list-select.component.html',
  styleUrls: ['./saved-list-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SavedListSelectComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    NgFor,
    MatOption,
    AsyncPipe,
  ],
})
export class SavedListSelectComponent implements ControlValueAccessor, OnDestroy {
  private _destroy$$: Subject<void> = new Subject();
  private _touch$$: Subject<void> = new Subject();

  private _selectFilterControl = new FormControl('');

  private _selectedId$$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  private _savedLists$$: BehaviorSubject<SearchableSavedListSearchItem[]> = new BehaviorSubject<SearchableSavedListSearchItem[]>([]);

  public form = new FormGroup({
    selectFilter: this._selectFilterControl,
  });

  private _savedLists$ = this.savedListsService.search(undefined, 1, 0, -1).pipe(
    map((r) =>
      r.data.map(
        (list): SearchableSavedListSearchItem =>
          Object.assign(list, {
            normalizedName: list.name.trim().toLowerCase(),
          } as SearchableSavedListSearchItem),
      ),
    ),
    tap((l) => this._savedLists$$.next(l)),
  );

  public filteredSavedLists$: Observable<SearchableSavedListSearchItem[]> = combineLatest([
    this._savedLists$,
    concat(of(''), this._selectFilterControl.valueChanges.pipe(debounceTime(250)) as Observable<SearchableSavedListSearchItem | string>),
  ]).pipe(
    map(([list, filterValue]) => {
      if (typeof filterValue === 'string') {
        const val = filterValue.toLowerCase().trim();
        return list.filter((filter) => filter.normalizedName.startsWith(val) || filter.normalizedName.includes(` ${val}`));
      } else if (filterValue) {
        return list.filter((filter) => filter.id === filterValue.id);
      }
      return list;
    }),
  );

  constructor(private readonly savedListsService: SavedListsService) {
    combineLatest([this._savedLists$$, this._selectedId$$.pipe(distinctUntilChanged())])
      .pipe(
        map(([list, id]) => {
          return list.find((l) => l.id === id);
        }),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        takeUntil(this._destroy$$),
      )
      .subscribe((v) => {
        if (this._selectFilterControl.value !== v?.id) {
          this._selectFilterControl.setValue(v?.id ?? null);
        }
      });

    this._selectFilterControl.valueChanges.subscribe((v: any) => {
      if (typeof v === 'object') {
        this._selectedId$$.next(v.id);
      } else {
        this._selectedId$$.next(undefined);
      }
      this._touch$$.next();
    });
  }

  public writeValue(value: string | undefined): void {
    this._selectedId$$.next(value);
  }

  public registerOnChange(fn: (value: string | undefined) => void): void {
    this._selectedId$$.pipe(distinctUntilChanged(), takeUntil(this._destroy$$)).subscribe(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this._touch$$.pipe(takeUntil(this._destroy$$)).subscribe(fn);
  }

  public trackById(_: number, item: SearchableSavedListSearchItem) {
    return item.id;
  }

  public displayFn = (item?: SearchableSavedListSearchItem): string => {
    return item?.name ?? '';
  };

  public optionSelected(value: SearchableSavedListSearchItem) {
    this.writeValue(value.id);
    this._touch$$.next();
  }

  public ngOnDestroy(): void {
    this._destroy$$.next();
  }
}
