import { signalStoreFeature, withState } from '@ngrx/signals';
import { withApplicationHeaderDrawers } from './withApplicationHeaderDrawers';
import { withHideHeaderOnScroll } from './withHideHeaderOnScroll';

export interface ApplicationHeaderSlice {}

export function withApplicationHeader() {
  return signalStoreFeature(
    withState<ApplicationHeaderSlice>(() => ({})),
    withApplicationHeaderDrawers(),
    withHideHeaderOnScroll(),
  );
}
