import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ideal-link-logo',
  standalone: true,
  imports: [],
  templateUrl: './link-logo.component.html',
  styleUrls: ['../logo-base/logo-base.scss', './link-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdealLinkLogoComponent {}
