import { Injectable, effect } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

//import { AuthenticationService } from '@conceptualpathways/cpoauth';
import { CustomerListItem, CustomersApi, ShipToListItem } from '@idealsupply/ngclient-webservice-customers';
import { AuthenticationService } from 'authentication-data';
import { BehaviorSubject, Observable, firstValueFrom, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customerNumberSubject: BehaviorSubject<CustomerListItem | undefined> = new BehaviorSubject<CustomerListItem | undefined>(
    undefined,
  );

  private readonly availableCustomerNumbers$ = toObservable(this.authenticationService.user).pipe(
    //tap(() => this.customerNumberSubject.next(undefined)),
    switchMap((user) =>
      user
        ? this.customersApi.getUsersCustomerAccounts()
        : of([{ customerNumber: '000002', customerName: 'Cash', customerExtendedName: 'Cash Only' } as CustomerListItem]),
    ),
    tap(() => this.ensureSelectedCustomerNumberIsAvailable()),
  );

  private readonly _availableCustomerNumbers = toSignal(this.availableCustomerNumbers$);
  private readonly _selectedCustomerNumber = toSignal(this.customerNumberSubject);

  public get customerNumberChange(): Observable<CustomerListItem | undefined> {
    return this.customerNumberSubject;
  }

  public get customerNumber(): CustomerListItem | undefined {
    return this._selectedCustomerNumber();
  }

  public get availableCustomerNumbersChange() {
    return this.availableCustomerNumbers$;
  }

  public get availableCustomerNumbers() {
    return this._availableCustomerNumbers() ?? [];
  }

  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly customersApi: CustomersApi,
  ) {
    effect(() => this.loadSelectedCustomerNumber(this._availableCustomerNumbers()), { allowSignalWrites: true });
    effect(() => this.saveSelectedCustomerNumber(this._selectedCustomerNumber()));
  }

  private loadSelectedCustomerNumber(availableCustomerNumbers: CustomerListItem[] | undefined): void {
    if (!availableCustomerNumbers) return;
    const storagePath = this.selectedCustomerNumberStoragePath;
    const cNum = (storagePath ? sessionStorage.getItem(storagePath) : undefined) || undefined;
    const item = availableCustomerNumbers.find((c) => c.customerNumber === cNum);
    if (item) {
      this.customerNumberSubject.next(item);
    } else {
      this.customerNumberSubject.next(availableCustomerNumbers[0]);
    }
  }

  private saveSelectedCustomerNumber(customerNumber: CustomerListItem | undefined): void {
    if (customerNumber !== undefined) {
      const storagePath = this.selectedCustomerNumberStoragePath;
      if (storagePath) {
        sessionStorage.setItem(storagePath, customerNumber?.customerNumber ?? '');
      }
    }
  }

  private ensureSelectedCustomerNumberIsAvailable(): void {
    const cNum = this.customerNumber?.customerNumber;
    if (cNum && !this.isCustomerNumberAvailable(cNum)) {
      this.customerNumberSubject.next(this.availableCustomerNumbers?.[0]);
    }
  }

  private get selectedCustomerNumberStoragePath(): string | null {
    const userId = this.authenticationService.user()?.id;
    return userId ? `com.idealsupply.customer-service.selectedCustomerNumber${userId}` : null;
  }

  public isCustomerNumberAvailable(cNum: string): boolean {
    return this.availableCustomerNumbers.some((c) => c.customerNumber === cNum);
  }

  public setSelectedCustomerNumber(cNum: string): void {
    const item = this.availableCustomerNumbers.find((c) => c.customerNumber === cNum);
    if (!item) {
      throw new Error(`Invalid customer number(${cNum}) for this user`);
    }
    this.customerNumberSubject.next(item);
  }

  public getShippingAddresses(): Promise<ShipToListItem[]> {
    const cNum = this.customerNumber?.customerNumber;
    if (cNum && cNum !== '000002') {
      return firstValueFrom(this.customersApi.getCustomerNumberShipto(cNum));
    }
    return Promise.resolve([]);
  }
}
