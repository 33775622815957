import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { AppShellStore } from '../../../../state/app-shell.store';

@Component({
  selector: 'ideal-app-header-handle',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './app-header-handle.component.html',
  styleUrl: './app-header-handle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.open]': '!headerHidden()',
    '[class.disabled]': 'scrollAtTop()',
    '(click)': 'toggleHeader()',
  },
})
export class AppHeaderHandleComponent {
  private readonly _store = inject(AppShellStore);
  protected readonly headerHidden = this._store.hideHeaderOnScrollIsHidden;
  protected readonly scrollAtTop = this._store.hideHeaderOnScrollIsAtTop;

  protected toggleHeader() {
    this._store.toggleHeader();
  }
}
