import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from '../cart.service';
import { DecimalPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'cart-count',
  templateUrl: './cart-count.component.html',
  styleUrls: ['./cart-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, DecimalPipe],
})
export class CartCountComponent implements OnInit, OnDestroy {
  private readonly subs: Subscription = new Subscription();
  public cartCount: number = 0;
  public cartCountChange: EventEmitter<number> = new EventEmitter();
  constructor(
    private readonly cartService: CartService,
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.cartService.cartChange.subscribe((cart) => {
        const count = cart?.items?.length || 0;
        if (this.cartCount !== count) {
          this.cartCount = count;
          this.cartCountChange.emit(count);
          this.changeRef.markForCheck();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
