import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { IdealLinkLogoComponent } from '../link-logo/link-logo.component';
import { TextLogoComponent } from '../text-logo/text-logo.component';

@Component({
  selector: 'ideal-horizontal-logo',
  standalone: true,
  imports: [IdealLinkLogoComponent, TextLogoComponent],
  templateUrl: './horizontal-logo.component.html',
  styleUrl: './horizontal-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalLogoComponent {
  public readonly color = signal('accent');
}
