<div class="title" (click)="handleTitleClick($event)">
  <a [routerLink]="['/products', item?.lineCode! + item?.partNumber!]" routerLinkActive="active">{{ item?.description }}</a>
</div>

<label class="lineCode">Product Line</label>
<div class="field lineCode">{{ item?.lineCode }}</div>

<label class="partNumber">Part Number</label>
<div class="field partNumber">{{ item?.partNumber }}</div>

<ng-container *ngIf="crossReference$ | async as crossRef">
  <label class="crossReference">Cross Reference</label>
  <div class="field crossReference">{{ crossRef }}</div>
</ng-container>

<label class="boxQuantity" *ngIf="(item?.boxQuantity || 0) > 1">Box Quantity</label>
<div class="field boxQuantity" *ngIf="(item?.boxQuantity || 0) > 1">
  {{ item?.boxQuantity }}
</div>

<label class="non-returnable" *ngIf="item?.returnable === false">This item is not returnable</label>
