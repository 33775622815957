import { computed, inject, PLATFORM_ID } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { AppDimension } from 'common-ui';
import { withAppBreakpoints } from './withAppBreakpoints';
import { isPlatformBrowser } from '@angular/common';
import { withActivePopover } from './withActivePopover';

export interface ApplicationMenuSlice {
  menuOpened: boolean;
}

export function withApplicationMenu() {
  return signalStoreFeature(
    withAppBreakpoints(),
    withActivePopover(),
    withState<ApplicationMenuSlice>(() => ({
      menuOpened: false,
    })),
    withComputed(({ appBreakpointWidth, menuOpened }) => {
      const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

      const menuIsInline = computed(() => {
        if (!isBrowser) return false;
        const appWidthBp = appBreakpointWidth();
        if (appWidthBp > AppDimension.md) {
          return true;
        }
        return false;
      });

      const menuIsOpen = computed(() => menuIsInline() || menuOpened());
      return {
        menuIsInline,
        menuIsOpen,
      };
    }),
    withMethods((state) => {
      const open = () => {
        patchState(state, { menuOpened: true, popoverActive: 'menu' });
      };
      const close = () => patchState(state, { menuOpened: false });
      const toggle = () => (state.menuOpened() ? close() : open());

      return {
        menuOpen: open,
        menuClose: close,
        menuToggle: toggle,
      };
    }),
    withHooks((state) => {
      return {
        onInit: () => {
          const appBreakpointWidth = state.appBreakpointWidth();
          if (appBreakpointWidth > AppDimension.md) {
            patchState(state, { menuOpened: true });
          }
        },
      };
    }),
  );
}
