import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { IdealLinkLogoComponent } from '../link-logo/link-logo.component';
import { TextLogoComponent } from '../text-logo/text-logo.component';

@Component({
  selector: 'ideal-vertical-logo',
  standalone: true,
  imports: [IdealLinkLogoComponent, TextLogoComponent],
  templateUrl: './vertical-logo.component.html',
  styleUrl: './vertical-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalLogoComponent {
  public readonly color = signal('brand');
}
