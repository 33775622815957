<mat-form-field>
  <mat-label>Quantity in Cart</mat-label>
  <input #q type="number" [formControl]="quantityField" matInput (change)="setQuantity(+q.value)" [disabled]="(busy | async) ?? false" />
  <mat-hint>
    <ng-container *ngIf="(item?.sellMultiple || 0) > 1">x {{ item?.sellMultiple | number }} =</ng-container>
    {{ (item?.sellMultiple || 1) * (+q.value || 0) | number }} total
  </mat-hint>
  <mat-error *ngIf="quantityField.hasError('available-exceeded')">Currently {{ this.totalAvailable | number }} in Stock</mat-error>
  <mat-error *ngIf="quantityField.hasError('min')">Must be 1 or more</mat-error>
  <mat-error *ngIf="quantityField.hasError('max')">Cannot be more than {{ quantityField.getError("max").max | number }}</mat-error>
  <button mat-icon-button matSuffix color="warn" (click)="remove()" [disabled]="(busy | async) ?? false">
    <mat-icon>delete</mat-icon>
  </button>
</mat-form-field>
