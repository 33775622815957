<table>
  <tr *ngFor="let attr of attributes; even as isOdd" [class.odd]="isOdd">
    <td>
      <label>{{ attr.title }}</label>
    </td>
    <td>
      <div *ngFor="let detail of attr.details">
        <ng-container [ngSwitch]="attrDetailType(detail)">
          <ng-container *ngSwitchCase="'link'"><a [href]="detail.spec" target="_blank">Download</a></ng-container>
          <ng-container *ngSwitchDefault
            ><p>{{ detail.spec }}</p></ng-container
          >
        </ng-container>
      </div>
    </td>
  </tr>
</table>
