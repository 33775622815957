import { PricedProductBase, ProductIdentifier, ProductPrice } from '@idealsupply/ngclient-webservice-inventory';

import Enumerable from 'linq';

export class ProductUtil {
  public static getProductId(prod: ProductIdentifier): string | undefined {
    return prod ? prod.lineCode + prod.partNumber : undefined;
  }

  public static isSameProduct(prod1: ProductIdentifier, prod2: ProductIdentifier): boolean {
    return ProductUtil.getProductId(prod1) === ProductUtil.getProductId(prod2);
  }

  private static _getSalesPriceRecord(prod: PricedProductBase): ProductPrice {
    return Enumerable.from(prod.pricing)
      .where((_) => _.source !== ProductPrice.SourceEnum.Quickbid || !_.requiredQuantity)
      .orderBy((_) => _.value)
      .first();
  }

  public static getSalesPrice(prod: PricedProductBase): number {
    return this._getSalesPriceRecord(prod).value;
  }

  public static getPricePer(prod: PricedProductBase): number {
    return this._getSalesPriceRecord(prod).valuePer ?? 1;
  }

  public static getFeeTotal(prod: PricedProductBase): number {
    const feePerEach = Enumerable.from(prod.fees).sum((_) => _.value ?? 0);
    return feePerEach * (prod.sellMultiple || 1);
  }

  public static getBasePrice(prod: PricedProductBase): number {
    const basePrice = Enumerable.from(prod.pricing).firstOrDefault((_) => _.source === ProductPrice.SourceEnum.Default);
    return basePrice?.value ?? 0;
  }

  public static getPricePerEach(prod: PricedProductBase): number {
    const price = ProductUtil.getSalesPrice(prod);
    const pricePer = ProductUtil.getPricePer(prod) || 1;
    return price / pricePer;
  }

  public static getPriceForQuantityInteger(prod: PricedProductBase, quantity: number, basePrice: boolean = false): number {
    const price = (basePrice ? ProductUtil.getBasePrice(prod) : ProductUtil.getSalesPrice(prod)) * 10000;
    const pricePer = ProductUtil.getPricePer(prod) || 1;
    const priceEach = price / pricePer;
    const pricePerMultiple = priceEach * (prod.sellMultiple || 1);
    return pricePerMultiple * quantity;
  }

  public static getFeesForQuantity(prod: PricedProductBase, quantity: number): number {
    return ProductUtil.getFeeTotal(prod) * quantity;
  }
}
