import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators, ReactiveFormsModule } from '@angular/forms';
import { BranchStockLevel, CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import linq from 'linq';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatHint, MatError, MatSuffix } from '@angular/material/form-field';

@Component({
  selector: 'cart-quantity-field',
  templateUrl: './cart-quantity-field.component.html',
  styleUrls: ['./cart-quantity-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    ReactiveFormsModule,
    MatInput,
    MatHint,
    NgIf,
    MatError,
    MatIconButton,
    MatSuffix,
    MatIcon,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class CartQuantityFieldComponent implements OnDestroy {
  public _item?: CartItem;
  public quantityField: FormControl;

  @Input()
  public get item(): CartItem | undefined {
    return this._item;
  }
  public set item(value: CartItem | undefined) {
    this._item = value;
    const q = value?.branchQuantities || [];
    this.quantityField.setValue(q[0].quantity);
    this.quantityField.markAllAsTouched();
  }

  private subs: Subscription = new Subscription();
  private quantityChangeSubject: Subject<number> = new Subject();

  public get busy() {
    return this.cartService.busy;
  }

  constructor(
    private readonly cartService: CartService,
    private readonly branchService: BranchService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
  ) {
    this.quantityField = fb.control('', {
      validators: [
        Validators.min(1),
        Validators.max(100000000),
        (control: AbstractControl): ValidationErrors | null => {
          const value = control.value || 0;
          if (!this._item?.allowBackorders) {
            return value <= this.totalAvailable ? null : { 'available-exceeded': true };
          } else {
            return this.totalAvailable > 0 ? null : { 'available-exceeded': true };
          }
        },
      ],
    });

    this.subs.add(
      this.quantityChangeSubject.pipe(debounceTime(250)).subscribe(async (value) => {
        await this.cartService.updateItemQuantity(
          this.item?.lineCode as string,
          this.item?.partNumber as string,
          value,
          this.getDefaultBranch(this.item),
        );
      }),
    );
  }

  public get totalAvailable(): number {
    const sum = this.item ? linq.from(this.item.stock).sum((_) => _.stock || 0) : 0;
    if (sum && this.item?.allowBackorders) {
      return 100000000;
    }
    return sum;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public async setQuantity(value: number): Promise<void> {
    const v = value || 1;
    if (v !== value) {
      this.quantityField.setValue(v);
    }
    this.quantityChangeSubject.next(v);
  }

  public updateItem(item: CartItem): void {
    this.item = item;
    this.changeRef.markForCheck();
  }

  public async remove(): Promise<void> {
    if (this.item) {
      const q = this.item.branchQuantities;
      try {
        this.item.branchQuantities = [];
        await this.cartService.updateItemQuantity(this.item.lineCode as string, this.item.partNumber, 0, this.getDefaultBranch(this.item));
      } catch (e) {
        this.item.branchQuantities = q;
        throw e;
      }
    }
  }

  private getDefaultBranch(item?: CartItem): string {
    if (item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.branchService.currentBranch;
      branch = item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }

      return branch?.branch as string;
    }
    return '';
  }
}
