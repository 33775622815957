import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { AuthenticationService } from '@conceptualpathways/cpoauth';
import { CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
//import { AlertsService } from '@nfc-authority/angular-web-components';

import { CustomerService } from 'lib-customer';
import { ProductUtil } from 'lib-products';
import { Subscription, firstValueFrom } from 'rxjs';
import { SaveCartDialogComponent } from '../../saved-carts';
import { SaveCartAsListDialogComponent } from '../../saved-lists';
import { SavedListsService } from '../../saved-lists/saved-lists.service';
import { CartService } from '../cart.service';
import { AuthenticationService } from 'authentication-data';
import { toObservable } from '@angular/core/rxjs-interop';
import { CartPanelItemComponent } from '../cart-panel-item/cart-panel-item.component';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';
import { ConfirmDialogResponse, DialogService } from 'common-ui';
import { AppService } from 'app-shell-ui';

@Component({
  selector: 'cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButton, RouterLink, MatIcon, NgFor, CartPanelItemComponent, AsyncPipe, CurrencyPipe],
})
export class CartPanelComponent implements OnDestroy {
  private subs: Subscription = new Subscription();

  public isAuthenticated$ = toObservable(this.authenticationService.isAuthenticated);

  public items: CartItem[] = [];
  constructor(
    private readonly cartService: CartService,
    private readonly authenticationService: AuthenticationService,
    private readonly savedListsService: SavedListsService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly dialogService: DialogService,
    private readonly dialog: MatDialog,
    private readonly appService: AppService,
  ) {
    this.subs.add(
      this.cartService.itemsChange.subscribe((items) => {
        this.items.splice(0, this.items.length, ...items);
        this.changeRef.markForCheck();
      }),
    );
  }

  public get subtotal(): number {
    return this.cartService.subtotal;
  }

  public get feeTotal(): number {
    return this.cartService.feeTotal;
  }

  public get savings(): number {
    return this.cartService.savings;
  }

  public get points(): number {
    return this.cartService.points;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public trackProductBy(idx: number, item: CartItem): string {
    return ProductUtil.getProductId(item) || ''; //+ '__' + item.quantity;
  }

  public async clearCart(): Promise<void> {
    const clear = await this.dialogService.confirm('Are you sure you wish to clear your shopping cart?', 'Clear Cart?', {
      negativeButtonHidden: true,
      positiveButtonWarn: true,
      positiveButtonLabel: 'Clear',
    });

    if (clear === ConfirmDialogResponse.Positive) {
      this.cartService.clearCart();
    }
  }

  public async saveCart(): Promise<void> {
    if (this.cartService.cart?.id && !!this.cartService.cart?.items.length) {
      const result = (await firstValueFrom(
        this.dialog
          .open(SaveCartDialogComponent, {
            data: { customerNumber: this.cartService.cart.customerNumber },
          })
          .afterClosed(),
      )) as { action: 'new' | 'merge' | 'replace'; value: string };
      if (result) {
        switch (result.action) {
          case 'new':
            await this.cartService.saveCartAs(result.value);
            break;
          case 'replace':
            await this.cartService.replaceSavedCart(+result.value);
            break;
          case 'merge':
            await this.cartService.mergeToSavedCart(+result.value);
            break;
        }
      }
    }
  }

  public async saveAsList(): Promise<void> {
    if (this.cartService.cart?.id && !!this.cartService.cart?.items.length) {
      const result = (await firstValueFrom(
        this.dialog
          .open(SaveCartAsListDialogComponent, {
            data: { customerNumber: this.cartService.cart.customerNumber },
          })
          .afterClosed(),
      )) as { action: 'new' | 'merge' | 'replace'; value: string };
      if (result) {
        switch (result.action) {
          case 'new':
            await this.savedListsService.createListFromCart(result.value);
            break;
          case 'replace':
            await this.savedListsService.replaceListFromCart(result.value);
            break;
          case 'merge':
            await this.savedListsService.mergeListFromCart(result.value);
            break;
        }
      }
    }
  }

  protected proceedToCartClicked() {
    //this.appService.sideDrawers.close('cart');
  }
}
