import { Injectable, effect } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BranchListItem, BranchesApi } from '@idealsupply/ngclient-webservice-branches';
import { map } from 'rxjs/operators';
import linq from 'linq';
import { AuthenticationService } from 'authentication-data';
@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private _homeBranchId: string | undefined;
  private _currentBranch: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  public readonly defaultBranches: string[] = ['98', '88'];

  constructor(
    private authService: AuthenticationService,
    private readonly branchesApi: BranchesApi,
  ) {
    // authService.userChange.subscribe((user) => {
    //   this._homeBranchId = Array.isArray(user?.home_branch) ? (user?.home_branch[0] as string) : undefined;
    //   if (this._currentBranch.value === undefined) {
    //     this._currentBranch.next(this._homeBranchId);
    //   }
    // });
    effect(() => {
      const user = this.authService.user();
      this._homeBranchId = user?.homeBranch;
      if (this._currentBranch.value === undefined) {
        this._currentBranch.next(this._homeBranchId);
      }
    });
  }

  public get currentBranchChange(): Observable<string | undefined> {
    return this._currentBranch;
  }

  public get currentBranch(): string | undefined {
    return this._currentBranch.value || this._homeBranchId;
  }

  public setCurrentBranch(branchId: string | undefined): void {
    if (branchId === undefined) {
      branchId = this._homeBranchId;
    }
    if (this._currentBranch.value !== branchId) {
      this._currentBranch.next(branchId);
    }
  }

  public getBranches(): Observable<Array<BranchListItem>> {
    return this.branchesApi.pickupGet().pipe(
      map((x) =>
        linq
          .from(x)
          .orderBy((x) => x.name)
          .toArray(),
      ),
    );
  }
}
