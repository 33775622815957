<header>
  <div class="cart-count">{{ items.length }}</div>
  <div class="cart-count-label">Items</div>
  <div class="line">
    <label>Subtotal:</label><span>{{ subtotal + feeTotal | currency }}</span>
  </div>
  <div class="line" *ngIf="savings > 0">
    <label>Savings:</label><span>{{ savings | currency }}</span>
  </div>
  <div class="line" *ngIf="points > 0">
    <label>Reward Points:</label><span>{{ points }}</span>
  </div>
  <button mat-raised-button color="primary" routerLink="/cart" (click)="proceedToCartClicked()">
    <mat-icon>shopping_cart</mat-icon><label>&nbsp;View Cart</label>
  </button>
</header>
<div class="cart-list-container">
  <ng-container *ngIf="!items?.length">You do not have any items in the cart.</ng-container>
  <ng-container *ngIf="!!items?.length">
    <cart-panel-item *ngFor="let item of items; trackBy: trackProductBy" [item]="item"></cart-panel-item>
  </ng-container>
</div>
<footer>
  <ng-container *ngIf="isAuthenticated$ | async">
    <button mat-stroked-button (click)="saveCart()">Save Cart</button>
    <button mat-stroked-button (click)="saveAsList()">Save As List</button>
  </ng-container>
  <button mat-stroked-button color="warn" (click)="clearCart()">Clear Cart</button>
</footer>
