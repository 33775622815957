<mat-card style="background-image: url(assets/dashboard/rewards.jpg)" class="fullwidth">
  <mat-card-header>
    <mat-card-title>{{ promo?.title }}</mat-card-title>
  </mat-card-header>

  <div mat-card-image>
    <svg viewBox="0 0 8.5 11"></svg>
    <img [src]="promo?.thumbnail" />
  </div>

  <mat-card-content>
    <p>{{ promo?.description }}</p>
  </mat-card-content>
</mat-card>
