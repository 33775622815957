import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { ProductListItem } from '@idealsupply/ngclient-webservice-inventory';

@Component({
  selector: 'product-thumbnail',
  templateUrl: './product-thumbnail.component.html',
  styleUrls: ['./product-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProductThumbnailComponent implements OnInit {
  @Input()
  public item: ProductListItem | undefined;

  @HostBinding('class.noimage')
  public get noimage(): boolean {
    return !this.item?.imageUrl;
  }

  constructor() {}

  ngOnInit(): void {}
}
