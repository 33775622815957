import { Injectable } from '@angular/core';
import { SavedList, SavedListApi } from '@idealsupply/ngclient-webservice-shopping-cart';
import { CustomerService } from 'lib-customer';
import { Observable, firstValueFrom } from 'rxjs';
import { CartService } from '../cart/cart.service';

const GUEST_CUSTOMER = '000002';

@Injectable({
  providedIn: 'root',
})
export class SavedListsService {
  constructor(
    private readonly savedListApi: SavedListApi,
    private readonly cartService: CartService,
    private readonly customerService: CustomerService,
  ) {}

  public search(customerNumber?: string, type?: 1 | 2, skip: number = 0, limit: number = 25) {
    return this.savedListApi.getSavedLists(customerNumber, type, skip, limit);
  }

  public getSavedListTypes() {
    return this.savedListApi.getSavedListTypes();
  }

  public deleteSavedList(listId: string) {
    return this.savedListApi.deleteSavedList(listId);
  }

  public getSavedList(listId: string, listType: 1 | 2) {
    return this.savedListApi.getSavedList(listId, listType) as unknown as Observable<SavedList>;
  }

  public async createListFromCart(listName: string) {
    const cart = this.cartService.cart;
    if (cart) {
      const result = await firstValueFrom(this.savedListApi.createSavedListFromCart(cart.id, listName));
      return result;
    }
    return undefined;
  }

  public async replaceListFromCart(listId: string) {
    const cart = this.cartService.cart;
    if (cart) {
      const result = await firstValueFrom(this.savedListApi.addToSavedListFromCart(listId, 'Replace', cart.id));
      return result;
    }
    return undefined;
  }

  public async mergeListFromCart(listId: string) {
    const cart = this.cartService.cart;
    if (cart) {
      const result = await firstValueFrom(this.savedListApi.addToSavedListFromCart(listId, 'Merge', cart.id));
      return result;
    }
    return undefined;
  }
}
