import { isPlatformBrowser } from '@angular/common';
import { effect, inject, isSignal, PLATFORM_ID } from '@angular/core';
import { patchState, signalStoreFeature, withHooks } from '@ngrx/signals';

export function withSessionPersist(sessionKey: string, keys: string[]) {
  return signalStoreFeature(
    withHooks((state) => {
      const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
      return {
        onInit() {
          if (!isBrowser) return;
          const sessionData = sessionStorage.getItem(sessionKey);

          effect(() => {
            const data: any = {};
            keys.forEach((key) => {
              const storeValue = (state as any)[key];
              if (isSignal(storeValue)) {
                data[key] = storeValue();
              }
            });

            const sessionData = JSON.stringify(data);
            sessionStorage.setItem(sessionKey, sessionData);
          });
          if (sessionData) {
            try {
              const data = JSON.parse(sessionData);
              patchState(state, data);
            } catch (e) {
              console.error('Error parsing session data', e);
            }
          }
        },
      };
    }),
  );
}
