import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, effect } from '@angular/core';
// import { AuthenticationService } from '@conceptualpathways/cpoauth';
// import { IUserInfo } from '@conceptualpathways/cpoauth/lib/models/IUserInfo';
import { FavoriteProduct, FavoriteProductApi, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { AuthenticationService, IdealUser } from 'authentication-data';
import { ElementQueries } from 'css-element-queries';
import { ProductListItemPriceComponent } from '../product-list-item-price/product-list-item-price.component';
import { ProductListItemDetailComponent } from '../product-list-item-detail/product-list-item-detail.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgStyle } from '@angular/common';
import { ProductThumbnailComponent } from '../product-thumbnail/product-thumbnail.component';

@Component({
  selector: 'product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ProductThumbnailComponent,
    NgIf,
    MatIconButton,
    NgStyle,
    MatIcon,
    ProductListItemDetailComponent,
    ProductListItemPriceComponent,
  ],
})
export class ProductListItemComponent implements OnInit {
  get favorite() {
    return this.item?.favorite || false;
  }
  public user: IdealUser | null = null;

  @Input()
  public item: ProductListItem | undefined;

  @Output()
  public itemClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();
  @Output()
  public thumbClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();
  @Output()
  public titleClick: EventEmitter<CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>> = new EventEmitter();

  @Input()
  public priceOverride?: number;

  @Input()
  public pricePerOverride?: number;

  @Input()
  public wasPriceOverride?: number;

  constructor(
    private readonly favoriteProductApi: FavoriteProductApi,
    private ref: ChangeDetectorRef,
    private readonly authService: AuthenticationService,
    private readonly changeRef: ChangeDetectorRef,
  ) {
    // this.authService.userChange.subscribe((user) => {
    //   this.user = user;
    //   this.changeRef.markForCheck();
    // });
    effect(() => {
      this.user = this.authService.user();
      this.changeRef.markForCheck();
    });
  }

  ngOnInit(): void {
    // ElementQueries.init();
  }

  public handleItemClick(event: MouseEvent): void {
    const cEvent: CustomEvent<{
      mouseEvent: MouseEvent;
      item: ProductListItem;
    }> = new CustomEvent('itemClick', {
      detail: { mouseEvent: event, item: this.item as ProductListItem },
    });
    this.itemClick.emit(cEvent);
  }
  public handleThumbClick(event: MouseEvent): void {
    const cEvent: CustomEvent<{
      mouseEvent: MouseEvent;
      item: ProductListItem;
    }> = new CustomEvent('thumbClick', {
      detail: { mouseEvent: event, item: this.item as ProductListItem },
    });
    event.stopPropagation();
    this.thumbClick.emit(cEvent);
    if (!cEvent.defaultPrevented) {
      this.handleItemClick(event);
    }
  }

  public handleTitleClick(event: CustomEvent<{ mouseEvent: MouseEvent; item: ProductListItem }>) {
    event.stopPropagation();
    this.titleClick.emit(event);
    if (!event.defaultPrevented) {
      this.handleItemClick(event.detail.mouseEvent);
    }
  }

  public addProductToFavorite() {
    this.item!.favorite = !this.favorite;

    let favoriteProduct: FavoriteProduct = {} as FavoriteProduct;
    favoriteProduct.lineCode = this.item?.lineCode || '';
    favoriteProduct.partNumber = this.item?.partNumber || '';

    if (this.favorite) {
      this.favoriteProductApi.createFavoriteProducts(favoriteProduct).subscribe({
        next: (data: FavoriteProduct) => {},
        error: (error: any) => {
          this.item!.favorite = !this.favorite;
          this.ref.detectChanges();
          console.error(error);
        },
      });
    } else {
      this.favoriteProductApi.deleteFavoriteProducts(favoriteProduct.lineCode, favoriteProduct.partNumber).subscribe({
        next: (data: any) => {},
        error: (error: any) => {
          this.item!.favorite = !this.favorite;
          this.ref.detectChanges();
          console.error(error);
        },
      });
    }
  }
}
