import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators, ReactiveFormsModule } from '@angular/forms';
import { Product, ProductListItem } from '@idealsupply/ngclient-webservice-inventory';
import { BranchStockLevel, CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import linq from 'linq';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'cart-add-to-cart',
  templateUrl: './cart-add-to-cart.component.html',
  styleUrls: ['./cart-add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatFormField,
    MatLabel,
    ReactiveFormsModule,
    MatInput,
    MatHint,
    MatError,
    MatIconButton,
    MatIcon,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class CartAddToCartComponent implements OnInit, OnDestroy {
  public _item?: CartItem;
  private subs: Subscription = new Subscription();
  private quantityChangeSubject: Subject<number> = new Subject();
  public quantityField: FormControl;

  @Input()
  public item?: CartItem | Product | ProductListItem;

  public get busy() {
    return this.cartService.busy;
  }

  public get cartItem(): CartItem | undefined {
    return this._item;
  }
  public set cartItem(value: CartItem | undefined) {
    this._item = value;
    const q = value?.branchQuantities || [];
    this.quantityField.setValue(q[0]?.quantity ?? 0);
    this.quantityField.markAllAsTouched();
  }

  public get itemQuantity(): number {
    return (this.cartItem?.branchQuantities || [])[0]?.quantity || 0;
  }

  @Output()
  public itemQuantityChange: EventEmitter<number> = new EventEmitter();
  @Output()
  public cartItemChange: EventEmitter<CartItem | undefined> = new EventEmitter();

  constructor(
    private readonly cartService: CartService,
    private readonly branchService: BranchService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
  ) {
    this.quantityField = fb.control('', {
      validators: [
        Validators.min(1),
        Validators.max(100000000),
        (control: AbstractControl): ValidationErrors | null => {
          const value = control.value || 0;
          if (!this._item?.allowBackorders) {
            return value <= this.totalAvailable ? null : { 'available-exceeded': true };
          } else {
            return this.totalAvailable > 0 ? null : { 'available-exceeded': true };
          }
        },
      ],
    });
  }

  public get totalAvailable(): number {
    const sum = this.item ? linq.from(this.item.stock).sum((_) => _.stock || 0) : 0;
    if (sum && this.item?.allowBackorders) {
      return 100000000;
    }
    return sum;
  }

  ngOnInit(): void {
    this.subs.add(
      this.cartService.itemsChange.subscribe((items) => {
        const ci = items.find((i) => i.lineCode === this.item?.lineCode && i.partNumber === this.item?.partNumber);
        if (this.cartItem !== ci) {
          this.cartItem = ci;
          this.cartItemChange.emit(ci);
          this.changeRef.markForCheck();
        }
      }),
    );

    this.subs.add(
      this.quantityChangeSubject.pipe(debounceTime(250)).subscribe(async (value) => {
        this.itemQuantityChange.emit(value);
        await this.cartService.updateItemQuantity(
          this.item?.lineCode as string,
          this.item?.partNumber as string,
          value,
          this.getDefaultBranch(this.item),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public async setQuantity(value: number): Promise<void> {
    const v = value || 1;
    if (v !== value) {
      this.quantityField.setValue(v);
    }
    this.quantityChangeSubject.next(v);
  }
  public async deleteItem(): Promise<void> {
    if (this.cartItem) {
      const q = this.cartItem.branchQuantities;
      try {
        this.cartItem.branchQuantities = [];
        this.itemQuantityChange.emit(0);
        await this.cartService.updateItemQuantity(
          this.cartItem.lineCode as string,
          this.cartItem.partNumber,
          0,
          this.getDefaultBranch(this.item),
        );
      } catch (e) {
        this.cartItem.branchQuantities = q;
        throw e;
      }
    }
  }

  private getDefaultBranch(item?: CartItem | Product | ProductListItem): string {
    if (item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.branchService.currentBranch;
      branch = item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }

      return branch?.branch as string;
    }
    return '';
  }
}
