<div (click)="handleItemClick($event)"></div>
<div thumb (click)="handleThumbClick($event)">
  <product-thumbnail [item]="item"></product-thumbnail>
  <ng-content select="[thumb]"></ng-content>
  <button
    class="facoriteButton"
    mat-icon-button
    (click)="addProductToFavorite()"
    *ngIf="item != undefined && user"
    [ngStyle]="{ color: favorite ? 'red' : 'grey' }"
    aria-label="Add product to favorites"
  >
    <mat-icon>favorite</mat-icon>
  </button>
</div>
<div detail (click)="handleItemClick($event)">
  <product-list-item-detail [item]="item" (titleClick)="handleTitleClick($event)"></product-list-item-detail>
  <ng-content select="[detail]"></ng-content>
</div>
<div price (click)="handleItemClick($event)">
  <product-list-item-price
    [item]="item"
    [priceOverride]="priceOverride"
    [wasPriceOverride]="wasPriceOverride"
    [priceOverride]="priceOverride"
  ></product-list-item-price>
  <ng-content select="[price]"></ng-content>
</div>
<div tool>
  <ng-content select="[tool]"></ng-content>
</div>
