import { Injectable, inject } from '@angular/core';
import { AppShellStore } from '../app-shell/state/app-shell.store';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly _appShellStore = inject(AppShellStore);

  public readonly headerDrawers = {
    isOpen: this._appShellStore.headerDrawerIsOpen,
    openedDrawer: this._appShellStore.headerDrawerOpened,
    open: this._appShellStore.headerDrawerOpen.bind(this._appShellStore),
    toggle: this._appShellStore.headerDrawerToggle.bind(this._appShellStore),
    close: this._appShellStore.headerDrawerClose.bind(this._appShellStore),
  } as const;

  public readonly sideDrawers = {
    isOpen: this._appShellStore.sideDrawerIsOpen,
    openedDrawer: this._appShellStore.sideDrawerOpened,
    open: this._appShellStore.sideDrawerOpen.bind(this._appShellStore),
    toggle: this._appShellStore.sideDrawerToggle.bind(this._appShellStore),
    close: this._appShellStore.sideDrawerClose.bind(this._appShellStore),
  } as const;

  public readonly menus = {
    isOpen: this._appShellStore.menuIsOpen,
    inline: this._appShellStore.menuIsInline,
    open: this._appShellStore.menuOpen.bind(this._appShellStore),
    toggle: this._appShellStore.menuToggle.bind(this._appShellStore),
    close: this._appShellStore.menuClose.bind(this._appShellStore),
  } as const;
}
