import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { BranchStockLevel, CartItem, ProductListItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import { Subscription } from 'rxjs';
import { CartService } from '../cart.service';
import { MatButton } from '@angular/material/button';
import { NgFor, AsyncPipe, DecimalPipe } from '@angular/common';
@Component({
  selector: 'cart-item-stock',
  templateUrl: './cart-item-stock.component.html',
  styleUrls: ['./cart-item-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogContent, MatRadioGroup, NgFor, MatRadioButton, MatDialogActions, MatButton, MatDialogClose, AsyncPipe, DecimalPipe],
})
export class CartItemStockComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private currentBranchId?: string;
  private _selectedBranch?: BranchStockLevel;

  private _item?: ProductListItem;

  @Input()
  public get item(): ProductListItem | undefined {
    return this._item;
  }
  public set item(value: ProductListItem | undefined) {
    this._item = value;
    this.cartItem = this.cartService.cart?.items.find((_) => _.lineCode + _.partNumber === '' + value?.lineCode + value?.partNumber);
    this._selectedBranch = this.cartItem?.stock?.find((_) => {
      return _.branch === (this.cartItem?.branchQuantities || [])[0]?.branchId;
    });
    this.totalStock =
      this.item?.stock?.reduce((acc, loc) => {
        return acc + (loc.stock || 0);
      }, 0) ?? 0;
  }
  public cartItem?: CartItem;

  protected totalStock: number = 0;

  public get busy() {
    return this.cartService.busy;
  }

  @ViewChild('stockDialogTpl', { static: true })
  private dialogTpl?: TemplateRef<any>;

  public get currentBranch(): BranchStockLevel | undefined {
    return this._selectedBranch || this.defaultBranch;
  }

  public get defaultBranch(): BranchStockLevel | undefined {
    if (this.item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.currentBranchId;
      branch = this.item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = this.item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            break;
          }
        }
      }

      return branch;
    }
    return undefined;
  }

  public get stock(): number {
    const stock = this.currentBranch?.stock || 0;
    return stock < 1 ? 0 : stock;
  }

  constructor(
    private branchService: BranchService,
    private cartService: CartService,
    private changeRef: ChangeDetectorRef,
    private readonly dialogService: MatDialog,
  ) {
    this.subscription.add(
      branchService.currentBranchChange.subscribe((branchId) => {
        this.currentBranchId = branchId;
        this.changeRef.markForCheck();
      }),
    );
    this.subscription.add(
      cartService.itemsChange.subscribe((items) => {
        this.cartItem = this.cartService.cart?.items.find(
          (_) => _.lineCode + _.partNumber === '' + this.item?.lineCode + this.item?.partNumber,
        );
        this._selectedBranch = this.cartItem?.stock?.find((_) => {
          return _.branch === (this.cartItem?.branchQuantities || [])[0]?.branchId;
        });
        this.changeRef.markForCheck();
      }),
    );
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public showPopup(): void {
    if (this.dialogTpl) {
      const dialogRef = this.dialogService.open(this.dialogTpl, {
        width: '95%',
        maxWidth: '45em',
        maxHeight: '99vh',
      });
    }
  }

  public async onBranchChange(change: MatRadioChange) {
    if (this.cartItem) {
      const sel = this.cartItem.stock?.find((_) => {
        return _.branch === change.value;
      });
      if (this._selectedBranch !== sel) {
        const current = (this.cartItem?.branchQuantities || [])[0];
        await this.cartService.updateItemQuantity(
          this.cartItem.lineCode,
          this.cartItem.partNumber,
          current?.quantity || 1,
          sel?.branch || this.defaultBranch?.branch,
        );
        this._selectedBranch = sel;
        this.changeRef.markForCheck();
      }
    }
  }
}
