<product-list
  [datasource]="datasource"
  (loadingChange)="loadingChange.emit($event)"
  (itemClick)="handleItemClick($event)"
  (thumbClick)="handleThumbClick($event)"
  (titleClick)="handleTitleClick($event)"
>
  <ng-template #toolTemplate let-product>
    <cart-add-remove-button tool [item]="product"></cart-add-remove-button>
    <cart-item-stock tool [item]="product" xngIf="item?.stock"></cart-item-stock>
    <a *ngIf="product.hasSupplementalProducts" [routerLink]="['/products', product.lineCode + product.partNumber, 'supplemental']"
      >View Supplemental Items</a
    >
    <a *ngIf="product.hasAlternateProducts" [routerLink]="['/products', product.lineCode + product.partNumber, 'alternates']"
      >View Supplemental Items</a
    >
  </ng-template>

  <ng-template #priceTemplate let-product>
    <cart-item-fee-calc price [item]="product"></cart-item-fee-calc>
  </ng-template>
</product-list>
