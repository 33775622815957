<ng-container *ngIf="vm$ | async as vm">
  <div *ngFor="let fee of vm.fees" class="fee" [ngClass]="[fee.feeType?.toLowerCase() || 'ehc']">
    <span>{{ (fee.value ?? 0) * vm.quantity | currency }}</span>
    <ng-container [ngSwitch]="fee.feeType" *ngIf="fee.value">
      <ng-container *ngSwitchCase="'EHC'">
        <label>EHC Fee</label>
      </ng-container>
      <ng-container *ngSwitchCase="'Core'">
        <label>Core Deposit</label>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
