<div class="item">
  <product-thumbnail [item]="item"></product-thumbnail>
  <div class="info">
    <div class="title">{{ item?.description }}</div>
    <div>{{ item?.lineCode }}{{ item?.partNumber }}</div>
    <cart-item-price-calc [item]="item" [always]="true"></cart-item-price-calc>
    <cart-item-fee-calc [item]="item" [useQuantity]="true"></cart-item-fee-calc>
  </div>
</div>
<div class="edit">
  @if (quantity() > 0) {
    <cart-add-to-cart [item]="item" (itemQuantityChange)="onItemQuantityChange($event)"></cart-add-to-cart>
  } @else {
    <div class="removing">Removing from cart...</div>
  }
</div>
