import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { withAppBreakpoints } from './withAppBreakpoints';

export interface ActivePopoverSlice {
  popoverActive: string | null;
}

export function withActivePopover() {
  return signalStoreFeature(
    withAppBreakpoints(),
    withState<ActivePopoverSlice>(() => ({
      popoverActive: null,
    })),
    withComputed((state) => ({
      popoverIsActive: computed(() => state.popoverActive() !== null),
    })),
    withMethods((state) => ({
      setPopoverActive: (name: string | null) => patchState(state, { popoverActive: name }),
    })),
  );
}
