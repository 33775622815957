import { ChangeDetectionStrategy, Component, Input, OnDestroy, signal } from '@angular/core';
import { BranchStockLevel, CartItem } from '@idealsupply/ngclient-webservice-shopping-cart';
import { BranchService } from 'lib-branches';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { CartAddToCartComponent } from '../cart-add-to-cart/cart-add-to-cart.component';
import { CartItemFeeCalcComponent } from '../cart-item-fee-calc/cart-item-fee-calc.component';
import { CartItemPriceCalcComponent } from '../cart-item-price-calc/cart-item-price-calc.component';
import { ProductThumbnailComponent } from 'lib-products';

@Component({
  selector: 'cart-panel-item',
  templateUrl: './cart-panel-item.component.html',
  styleUrls: ['./cart-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProductThumbnailComponent, CartItemPriceCalcComponent, CartItemFeeCalcComponent, CartAddToCartComponent],
  host: {
    '[class.no-quantity]': 'quantity() === 0',
  },
})
export class CartPanelItemComponent implements OnDestroy {
  private subs: Subscription = new Subscription();
  private quantityChangeSubject: Subject<number> = new Subject();
  protected quantity = signal<number>(1);

  @Input()
  public item?: CartItem;

  constructor(
    private readonly cartService: CartService,
    private readonly branchService: BranchService,
  ) {
    this.subs.add(
      this.quantityChangeSubject.pipe(debounceTime(250)).subscribe(async (value) => {
        await this.cartService.updateItemQuantity(
          this.item?.lineCode as string,
          this.item?.partNumber as string,
          value,
          this.getDefaultBranch(this.item),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public async deleteItem(): Promise<void> {
    await this.setQuantity(0);
  }

  public async setQuantity(value: number): Promise<void> {
    this.quantityChangeSubject.next(value);
  }

  private getDefaultBranch(item?: CartItem): string {
    if (item) {
      let branch: BranchStockLevel | undefined;
      let bId = this.branchService.currentBranch;
      branch = item.stock?.find((_) => {
        return _.branch === bId;
      });

      if (!branch || !branch.stock) {
        for (bId of this.branchService.defaultBranches) {
          branch = item.stock?.find((_) => {
            return _.branch === bId;
          });
          if (branch && branch.stock) {
            if (branch.stock < 1) {
              branch.stock = 0;
            }
            break;
          }
        }
      }

      return branch?.branch as string;
    }
    return '';
  }

  protected onItemQuantityChange(q: number) {
    this.quantity.set(q);
  }
}
