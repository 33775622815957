import { Inject, Injectable, Optional } from '@angular/core';
import { BASE_PATH, PunchOutApi, PunchOutIntegration } from '@idealsupply/ngclient-webservice-shopping-cart';
import { Hash } from 'fast-sha256';
import { CustomerService } from 'lib-customer';
import { filter, map, switchMap } from 'rxjs/operators';
import { CartService } from '../cart';

interface IPopup {
  poi: PunchOutIntegration;
  popup: Window;
}

@Injectable({
  providedIn: 'root',
})
export class PunchOutIntegrationService {
  private _popupsById: Map<string, IPopup> = new Map();

  constructor(
    private readonly cartService: CartService,
    private readonly customerService: CustomerService,
    private readonly punchOutApi: PunchOutApi,
    @Optional() @Inject(BASE_PATH) private readonly basePath: string,
  ) {
    this.basePath ||= 'https://api.idealsupply.com/shopping-cart/v0';
  }

  // public readonly integrations$ = this.cartService.cartChange.pipe(
  //   filter((cart) => !!cart?.customerNumber),
  //   switchMap((cart) => this.punchOutApi.getPunchOuts(cart!.customerNumber!)),
  //   map((integrations) => {
  //     integrations.push({
  //       id: 2,
  //       label: "Schneider's EZ List",
  //       url: './schneiders-ez-list.html',
  //     });
  //     return integrations;
  //   }),
  // );
  public readonly integrations$ = this.customerService.customerNumberChange.pipe(
    filter((cn) => !!cn?.customerNumber),
    switchMap((cn) => this.punchOutApi.getPunchOuts(cn?.customerNumber!)),
    map((integrations) => {
      integrations.push({
        id: 2,
        label: "Schneider's EZ List",
        url: './schneiders-ez-list.html',
      });
      return integrations;
    }),
  );

  /*
    public integrations$ = this.cartService.cartChange.pipe(
      map(cart => {
        if (!cart) {
          return [];
        }
        const customerNumber = this.cartService.cart?.customerNumber ?? '000002';
        return INTEGRATIONS.filter(i => !i.disableForCashCustomer || customerNumber !== '000002');
      })
    );
    */
  public ezAddToCart(
    items: {
      designation: string;
      catalogNo: string;
      gtin: string;
      quantity: number;
    }[],
  ) {
    //this.cartService.updateItemQuantity()
    items.forEach((item) => {
      const lineCode = '3SQ';
      const partNumber = item.catalogNo;
      const existing = this.cartService.cart?.items.find((i) => i.lineCode === lineCode && i.partNumber === partNumber);
      const currentCount = existing?.branchQuantities?.find((b) => !b.branchId)?.quantity ?? 0;
      const q = currentCount + item.quantity;
      this.cartService.updateItemQuantity(lineCode, partNumber, q);
    });
  }

  public open(poi: PunchOutIntegration): void {
    if (!this._hasPopup(poi)) {
      this._openPopup(poi);
    }
    const p = this._getPopup(poi);
    p?.popup.focus();

    const eventListener = ((event: MessageEvent) => {
      if (event.isTrusted && event.origin === 'https://ezlist.schneider-electric.com') {
        switch (event.data.fn) {
          case 'cnsAddToProject':
            if (event.data.IsValid) {
              this.ezAddToCart(JSON.parse(event.data.data));
            }
            p?.popup.close();
            break;
        }
      }
    }).bind(this);
    p?.popup.addEventListener('message', eventListener);

    const interval = setInterval(() => {
      if (p?.popup.closed) {
        clearInterval(interval);
        p?.popup.removeEventListener('message', eventListener);
      }
    }, 500);
  }

  private _getPopupId(poi: PunchOutIntegration) {
    return `ideal_poi_popup_${poi.id}`;
  }
  private _getPopup(poi: PunchOutIntegration) {
    return this._popupsById.get(this._getPopupId(poi));
  }

  private _hasPopup(poi: PunchOutIntegration) {
    const popupId = this._getPopupId(poi);
    const p = this._popupsById.get(popupId);
    return !!p && !p.popup.closed;
  }

  private _openPopup(poi: PunchOutIntegration) {
    if (this.cartService.cart) {
      const cart = this.cartService.cart;
      const hashParts = [cart.id, cart.type || '', cart.customerNumber || ''];

      const hash = new Hash();
      hash.update(new TextEncoder().encode(hashParts.join('|')));
      const h = btoa(String.fromCharCode.apply(null, hash.digest() as any));
      const url =
        poi.url?.startsWith('https://') || poi.url?.startsWith('http://') || poi.url?.startsWith('//') || poi.url?.startsWith('./')
          ? poi.url
          : `${this.basePath}${poi.url}/${cart.id}?hash=${h}`;

      const popupId = `ideal_poi_popup_${poi.id}`;
      const features = `popup=true,width=${poi.width || 1050},height=${poi.height || 800}`;
      const popup = window.open(url, popupId, features);
      if (popup) {
        this._popupsById.set(popupId, {
          poi,
          popup,
        });
      }
    }
  }
}
