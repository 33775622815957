import { SavedCartSearchItem, SavedListSearchItem } from '@idealsupply/ngclient-webservice-shopping-cart';

import { IPagedResult, PagedDataSource } from 'lib-products';
import { CartService } from '../../cart/cart.service';

export interface ISavedCartsFilter {
  customerNumber?: string;
}

export class SavedCartsDataSource extends PagedDataSource<SavedCartSearchItem> {
  private _filter: ISavedCartsFilter = {};

  constructor(
    private readonly service: CartService,
    skip: number = 0,
    limit: number = 10,
  ) {
    super(skip, limit);
  }

  private cleanFilter(data: ISavedCartsFilter): ISavedCartsFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const val = (data as any)[key];
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  public get customerNumber(): string | undefined {
    return this._filter.customerNumber;
  }

  public set customerNumber(value: string | undefined) {
    value = value || undefined;
    if (this.customerNumber !== value) {
      this.changed = true;
      this._filter.customerNumber = value;
      this.invalidateResults();
    }
  }

  public get filter(): ISavedCartsFilter {
    return this._filter;
  }

  public set filter(value: ISavedCartsFilter) {
    value ??= {};
    if (JSON.stringify(this.filter) !== JSON.stringify(value)) {
      this.changed = true;
      this._filter = value;
      this.invalidateResults();
    }
  }

  protected async update(): Promise<IPagedResult<SavedCartSearchItem>> {
    const f = this._filter ?? {};
    this.cleanFilter(f);

    const result = await this.service.searchSavedCarts(this.customerNumber, this.skip, this.limit);
    return result!;
  }

  public override initialize(value?: ISavedCartsFilter): Promise<this> {
    this._filter = value ?? {};
    return super.initialize();
  }
}
